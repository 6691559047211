

$primary: #009444;
$light: #F8F9F9;
$danger: #F0544F;
$secondary: #303539;
$dark: #40414E;
$success: #48A56A;

$theme-colors: (
        "primary": $primary,
        "light": $light,
        "danger": $danger,
        "secondary": $secondary,
        "dark": $dark,
        "success": $success,
);

.bg-light {
  background: #F8F9F9 !important;
}
.bg-dark {
  background: #40414E !important;
}

.bg-primary {
  background: #009444 !important;
}

.bg-danger {
  background: #F0544F !important;
}

.bg-secondary {
  background: #303539 !important;
}

.bg-success {
  background: #48A56A !important;
}


.text-light {
  color: #F8F9F9 !important;
}
.text-dark {
  color: #40414E !important;
}

.text-primary {
  color: #009444 !important;
}

.text-danger {
  color: #F0544F !important;
}

.text-secondary {
  color: #303539 !important;
}

.text-success {
  color: #48A56A;
}


