
/*user widgets*/
.user-widgets {
    text-align: right;
}

.user-widgets.user-widgets-left {
    text-align: left;
}

.user-widgets.user-widgets-left .widget-item:first-of-type {
    padding-left: 0;
}

.user-widgets .widget-item {
    display: inline-block;
    padding: 0 5px;
    position: relative;
}

.user-widgets .widget-item .fav-num {
    position: absolute;
    left: 21px;
    top: -9px;
    background-color: #F0544F;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    border-radius: 100%;

}

.user-widgets .widget-item .cart-num {
    position: absolute;
    left: 21px;
    top: -9px;
    background-color: #F0544F;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    border-radius: 100%;

}

.user-widgets .widget-item .cart-sum {
    display: inline-block;
    font-size: 12px;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.user-widgets .widget-item .icn {
    height: 25px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.user-widgets .widget-item:hover .icn {
    opacity: .4;
}

/*user nav*/
.user-widget .user-nav {
    position: absolute;
    top: 43px;
    right: 0;
    z-index: 3;
    background-color: #fff;
    padding: 10px 0 10px;
    text-align: left;
    display: none;
    border-top: 2px solid #d9d9d9;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.user-widget .user-nav:before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    top: -26px;
}

.user-widget .user-nav:after {
    content: "";
    height: 2px;
    width: 33px;
    position: absolute;
    top: -2px;
    right: 0;
    background-color: #009444;
    z-index: 3;
}

.user-widget .user-nav ul li {
    white-space: nowrap;
}

.user-widget .user-nav ul li a {
    display: block;
    position: relative;
    padding: 5px 20px;
    font-size: 15px;

}

.user-widget:hover .user-nav {
    display: block;
}

.user-widget .user-nav,
.sub-lang {
    z-index: 125;
}

@media only screen and (min-width: 767px) and (max-width: 991px) {

    .user-widgets .widget-item .icn {
        height: 20px;
    }

    .user-widgets .widget-item .fav-num {
        left: 21px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px)  {
    .user-widget .user-nav:after {
        width: 30px;
        left: inherit;
        right: 0;
    }

    .user-widget .user-nav {
        top: 36px;
        left: 0;
        right: inherit;
    }

    .user-widgets .widget-item .cart-sum {
        display: none;
    }

    .user-widgets .widget-item.fav-widget {
        display: none;
    }

    .user-widgets {
        text-align: right;
        padding: 10px 0 15px;
    }

    .user-widget .user-nav:before {
        display: none
    }

    .user-widgets .widget-item {
        padding: 0 6px;
    }
    .user-widgets .widget-item .icn {
        height: 20px;
    }
}