@media only screen and (min-width: 1400px) and (max-width: 4000px) {
    .intro h2 {
        font-size: 52px;
        line-height: 60px;
        margin: 0 0 30px 0;
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .products-by-category .category-product-block:hover .hover-info .btn {
        min-width: 160px;
    }

    nav .menu .menu-item .menu-link {
        font-size: 22px;
        padding: 6px 0 8px 0;
    }

    nav .menu .menu-item {
        display: inline-block;
        margin: 0 16px -1px 16px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 1200px) {
    .add-review .review-img {
        padding-left: 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .top .top-item {
        margin-right: 23px;
    }

    nav .menu .menu-item {
        margin: 0 18px;
    }

    .products-by-category .category-product-block:hover .hover-info .btn {
        min-width: 120px;
        font-size: 20px;
        padding: 0 15px;
    }

    h3 {
        font-size: 34px;
        line-height: 40px;
    }

    nav .menu .menu-item {
        margin: 0 14px;
    }

    nav .menu .menu-item .menu-link {
        font-size: 22px;
        padding: 6px 0 8px 0;
    }

    .about-us .hero-text {
        min-height: 300px;
    }

    .about-us .hero-text h1 {
        font-size: 65px;
        line-height: 72px;
    }

    .our-showroom .gallery-block .col-block {
        padding: 8px;
    }

    .our-showroom .gallery-block .col-block .img-block {
        margin-bottom: 14px;
    }

    .our-showroom .gallery-block .col-block .img-block.two-row:first-child {
        margin-right: 8px;
    }

    .our-showroom .gallery-block .col-block .img-block.two-row:last-child {
        margin-left: 8px;
    }

}

@media only screen and (min-width: 0px) and (max-width: 991px) {

    .mega-menu {
        width: 100%;
    }

    .mega-menu ul {
        padding-left: 15px;
    }

    nav .mega-menu ul li a {
        font-size: 20px;
        color: #fff;
        line-height: 24px;
        width: 100%;
        padding: 8px 15px 7px 15px;
        border-bottom: 1px solid #009444;
    }

}

@media only screen and (min-width: 767px) and (max-width: 991px) {

    .profile .heading {
        text-align: left;
        margin: 0 0 10px 0;
    }

    .banners .small-banner h2:after,
    .banners .small-banner h2:before,
    .banners .big-banner h2:before,
    .banners .big-banner h2:after {
        display: none;
    }

    .blog-list .blog-item-content {
        margin: 0;
    }

    .blog-article-content h1 {
        margin: 0;
    }

    .blog-article-content .meta {
        margin: 15px 0 20px 0;
    }

    .blog-article-content h1 {
        font-size: 36px;
        line-height: 42px;
    }

    .contacts .contact-form {
        padding: 0 0 65px 0;
    }

    .mission {
        margin: 50px 0;
        padding: 70px 0;
    }

    .mission .mission-block p {
        font-size: 30px;
        line-height: 36px;
        margin: 45px 0 0 0;
    }

    .mission .mission-block {
        padding: 30px 0;
    }


    .page-info {
        padding: 10px 0;
        margin: 0 0 10px 0;
    }

    .space {
        padding: 50px 0;
    }

    .nav-btn {
        z-index: 105;
        width: 30px;
        height: 18px;
        cursor: pointer;
        position: relative;
        top: 4px;
    }

    .nav-btn span {
        display: block;
        height: 4px;
        background-color: #303539;
        position: absolute;
        right: 0;
        top: 0;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);

        -webkit-transition: .1s ease-in-out;
        -moz-transition: .1s ease-in-out;
        -o-transition: .1s ease-in-out;
        transition: .1s ease-in-out;

        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .nav-btn span:nth-child(1) {
        width: 100%;
    }

    .nav-btn span:nth-child(2) {
        width: 100%;
        top: 7px;
    }

    .nav-btn span:nth-child(3) {
        width: 100%;
        top: 14px;
    }

    .nav-btn:hover span:nth-child(2) {
        width: 100%;
    }

    .nav-btn:hover span:nth-child(3) {
        width: 100%;
    }

    .nav-btn.open {
        transition-duration: .3s;
    }

    .nav-btn.open span {
        box-shadow: none;
        background-color: #303539;
        right: -5px;
    }

    .nav-btn.open span:nth-child(1) {
        width: 100%;
        transform: rotate(45deg);
        top: -3px;
    }

    .nav-btn.open span:nth-child(2) {
        opacity: 0;
    }

    .nav-btn.open span:nth-child(3) {
        width: 100%;
        transform: rotate(-45deg);
        top: 18px;
    }

    nav {
        height: 0;
        overflow: hidden;
    }

    nav.open {
        height: 100%;
    }

    header,
    header.fixed {
        padding: 10px 0;
    }

    header .logo {
        margin: 0 auto;
        display: table;
    }

    header .logo img {
        height: 65px;
    }

    header.fixed .logo img {
        height: 50px;
    }

    header nav {
        display: block;
        width: 100%;
        margin: 0;
    }

    header .menu {
        float: none;
        padding-left: 0;
        overflow: auto;
        position: relative;
        margin: 10px 0 15px 0;
        height: 100vh;
        padding-bottom: 55px;
    }

    nav .menu .menu-item {
        padding: 0;
        margin: 0;
        display: block;
        position: relative;
    }

    nav .menu .menu-item.active .menu-link {
        color: #fff;
    }

    nav .menu .menu-item.active .menu-link:before {
        display: none;
    }

    nav .menu .menu-item .dropdown-btn {
        position: absolute;
        right: 0;
        top: 0;
        border-left: 1px solid #cccccc;
        display: block;
        height: 100%;
        width: 50px;
        line-height: 36px;
        text-align: center;
        opacity: .7;
        margin: 0;
    }

    nav .menu .menu-item .menu-link,
    header.fixed nav .menu .menu-item .menu-link {
        padding: 5px 45px 5px 15px;
        display: block;
        border-bottom: 1px solid #cccccc;
        font-size: 16px;
    }

    nav .menu .menu-item:hover .menu-link:before {
        display: none;
    }

    .mega-menu .mega-menu-cat {
        position: relative;
        padding-left: 15px;
    }

    .mega-menu .mega-menu-cat .mega-menu-list {
        display: none;
    }

    .mega-menu .mega-menu-list.red-dot > li:before {
        display: none;
    }

    .sub-menu {
        display: none;
    }

    nav .menu .sub-menu {
        padding: 0 0 0 25px;
        background-color: initial;
    }

    .mega-menu .mega-menu-list > li,
    .mega-menu .mega-menu-list li {
        padding: 0;
        margin: 0;
        position: relative;
    }

    .mega-menu .mega-menu-list li a,
    .mega-menu .mega-menu-list.red-dot > li > a,
    nav .menu .sub-menu .sub-menu-link {
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        letter-spacing: 0;
        width: initial;
        text-transform: uppercase;
        font-weight: normal;
        padding: 8px 50px 8px 0;
        display: block;
        border-bottom: 1px solid #cccccc;
    }

    .mega-menu .mega-menu-list > li > ul {
        margin: 0;
        display: none;
    }

    .mega-menu .mega-menu-list a {
        padding-left: 25px;
    }

    .dropdown-btn ~ ul {
        position: initial !important;
        left: initial !important;
        right: initial !important;
        top: initial !important;
        border: 0 !important;
        box-shadow: initial !important;
        padding-left: 15px;
    }

    .mega-menu {
        position: initial !important;
        left: initial !important;
        right: initial !important;
        top: initial !important;
        border: 0 !important;
        box-shadow: initial !important;
        padding: initial !important;
        background-color: initial !important;
    }

    .mega-menu h5 {
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        letter-spacing: 0;
        position: relative;

        padding: 5px 40px 5px 15px;
        display: block;
        border-bottom: 1px solid #7a4e7b;
        color: #fff;
    }

    .mega-menu-banner {
        display: none;
    }

    .mega-menu-list {
        margin: 0 0 0 0;
    }

    .heading {
        margin: 0 0 50px 0;
        font-size: 35px;
        line-height: 40px;
    }

    .newsletter .btn {
        margin: 15px 0 0 0;
    }

    .intro h2 {
        font-size: 40px;
        line-height: 50px;
    }

    .newsletter .btn {
        margin: 0 0 0 0;
    }

    .search {
        max-width: 100%;
        min-width: inherit;
        display: none;
        margin: 0 0 15px 0;
    }

    footer {
        padding: 50px 0 30px;
    }

    footer .footer-menu {
        margin: 0 0 30px 0;
    }

    footer h3 {
        margin: 0 0 10px 0;
        font-size: 20px;
        line-height: 24px;
    }

    footer ul li {
        margin-bottom: 10px;
    }

    footer .footer-contacts li {
        font-size: 16px;
    }

    footer .footer-logo {
        height: 85px;
    }

    footer .copyright {
        padding-top: 30px;
        font-size: 15px;
        margin: 15px 0 0 0;
    }

    footer ul li a, footer ul li .address-info {
        font-size: 14px;
    }

    .countries .view-all {
        float: none;
        padding: 11px 0;
        text-align: center;
    }

    .countries .country {
        padding: 10px 3px;
    }

    header.fixed ~ .page {
        padding-top: 62px !important;
    }

    .products-by-category .category-product-block:hover .hover-info .btn {
        margin-top: 10px;
        min-width: 100px;
        font-size: 18px;
        padding: 0 15px;
    }

    .products-by-category .category-product-block h3 {
        font-size: 28px;
        line-height: 40px;
    }

    h1 {
        font-size: 42px;
        line-height: 46px;
        margin: 0 0 40px 0;
    }

    .order-auth-reg p {
        font-size: 15px;
    }

    .order-auth-reg .btn,
    .order-auth-login button,
    .login button {
        line-height: 40px;
    }

    .thankyou .thankyou-cups {
        margin: 0 auto 30px;
        width: 120px;
    }

    .contacts .contact-info {
        padding: 0 15px 0 0;
    }

    .contacts .contact-info ul li {
        padding: 12px 0 18px 0;
    }

    .contacts .contact-info ul li p,
    .contacts .contact-info ul li a {
        font-size: 15px;
    }

    .about-us .hero-text {
        min-height: 235px;
    }

    .about-us .hero-text h1 {
        font-size: 55px;
        line-height: 64px;
    }

    .our-showroom .gallery-block .col-block {
        padding: 5px;
    }

    .our-showroom .gallery-block .col-block .img-block {
        margin-bottom: 10px;
    }

    .our-showroom .gallery-block .col-block .img-block.two-row:first-child {
        margin-right: 6px;
    }

    .our-showroom .gallery-block .col-block .img-block.two-row:last-child {
        margin-left: 6px;
    }

}

@media only screen and (min-width: 0px) and (max-width: 767px) {

    .empty-blog-list h2 {
        padding: 15px 0;
        text-align: left;
    }

    .blog-list .blog-item-content {
        padding: 30px 30px;
        margin: 15px;
    }

    .blog-article-content {
        padding: 30px 15px 0 15px;
        margin: 0 0 30px 0;
    }

    .blog-article-content h1 {
        padding: 0 0;
    }

    .blog-article-content .meta {
        margin: 0px 0 20px 0;
    }

    .blog-list .blog-item {
        margin: 0 0 30px 0;
    }

    .blog-filter {
        margin: 0 0 20px 0;
    }

    .border-block-left {
        padding: 15px 0;
        margin: 0 0 0 0;
    }

    .brand {
        margin: 0 0 50px 0;
    }

    .border-block-right {
        padding: 15px 0;
        margin: 0 0 0 0;
    }

    .border-block-right:after {
        display: none;
    }

    .mission {
        margin: 50px 0;
        padding: 70px 0;
    }

    .mission .mission-block p {
        font-size: 20px;
        line-height: 26px;
        margin: 45px 0 0 0;
    }

    .mission .mission-block {
        padding: 30px 0;
    }

    .border-block-left:after {
        display: none;
    }

    .about-us .heading {
        margin: 0 0 10px 0;
    }

    .company ul li {
        margin: 5px 0;
    }

    .product-details {
        margin: 20px 0 0 0;
    }

    .thumbnails {
        margin: 0;
    }

    .thumbnail {
        padding: 10px;
        border: 1px solid #000;
        margin: 0 0 5px 0;
    }

    .product .product-add-fav {
        text-align: center;
        line-height: 18px;
    }

    .delivery-benefits {
        padding: 20px 0;
        margin: 0 0 20px 0;
    }

    .category-text {
        margin: 0;
    }

    .checkout-modal h5 {
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
    }

    .checkout-modal .icn {
        margin: 0 auto 20px;
        height: 50px;
    }

    body {
        font-size: 16px;
    }

    h4 {
        font-size: 24px;
        line-height: 28px;
    }

    p {
        font-size: 14px;
    }

    .mega-menu .mega-menu-container {
        overflow: hidden;
    }

    .page-info {
        padding: 10px 0;
        margin: 0 0 0 0;
    }

    .space-bot {
        padding-bottom: 50px;
    }

    .space {
        padding: 50px 0;
    }

    .nav-btn {
        z-index: 105;
        width: 25px;
        height: 18px;
        cursor: pointer;
        position: relative;
        top: 5px;
    }

    .nav-btn span {
        display: block;
        height: 4px;
        background-color: #303539;
        position: absolute;
        right: 0;
        top: 0;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);

        -webkit-transition: .1s ease-in-out;
        -moz-transition: .1s ease-in-out;
        -o-transition: .1s ease-in-out;
        transition: .1s ease-in-out;

        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .nav-btn span:nth-child(1) {
        width: 100%;
    }

    .nav-btn span:nth-child(2) {
        width: 100%;
        top: 7px;
    }

    .nav-btn span:nth-child(3) {
        width: 100%;
        top: 14px;
    }

    .nav-btn:hover span:nth-child(2) {
        width: 100%;
    }

    .nav-btn:hover span:nth-child(3) {
        width: 100%;
    }

    .nav-btn.open {
        transition-duration: .3s;
    }

    .nav-btn.open span {
        box-shadow: none;
        background-color: #303539;
        right: -5px;
    }

    .nav-btn.open span:nth-child(1) {
        width: 100%;
        transform: rotate(45deg);
        top: -1px;
    }

    .nav-btn.open span:nth-child(2) {
        opacity: 0;
    }

    .nav-btn.open span:nth-child(3) {
        width: 100%;
        transform: rotate(-45deg);
        top: 17px;
    }

    nav {
        height: 0;
        overflow: hidden;
    }

    nav.open {
        height: 100%;
    }

    header,
    header.fixed {
        padding: 10px 0;
    }

    header .logo {
        margin: 0 auto;
        display: table;
    }

    header .logo img {
        height: 65px;
    }

    header.fixed .logo img {
        height: 50px;
    }

    header nav {
        display: block;
        width: 100%;
        margin: 0;
    }

    header .menu {
        float: none;
        padding-left: 0;
        overflow: auto;
        position: relative;
        margin: 10px 0 15px 0;
        height: 100vh;
        padding-bottom: 55px;
    }

    nav .menu .menu-item {
        padding: 0;
        margin: 0;
        display: block;
        position: relative;
    }

    nav .menu .menu-item.active .menu-link {
        color: #fff;
    }

    nav .menu .menu-item.active .menu-link:before {
        display: none;
    }

    nav .menu .menu-item .dropdown-btn {
        position: absolute;
        right: 0;
        top: 0;
        border-left: 1px solid #cccccc;
        display: block;
        height: 100%;
        width: 50px;
        line-height: 36px;
        text-align: center;
        opacity: .7;
        margin: 0;
    }

    nav .menu .menu-item .menu-link,
    header.fixed nav .menu .menu-item .menu-link {
        padding: 5px 45px 5px 15px;
        display: block;
        border-bottom: 1px solid #cccccc;
        font-size: 16px;
    }

    nav .menu .menu-item:hover .menu-link:before {
        display: none;
    }

    .mega-menu .mega-menu-cat {
        position: relative;
        padding-left: 15px;
    }

    .mega-menu .mega-menu-cat .mega-menu-list {
        display: none;
    }

    .mega-menu .mega-menu-list.red-dot > li:before {
        display: none;
    }

    .sub-menu {
        display: none;
    }

    nav .menu .sub-menu {
        padding: 0 0 0 25px;
        background-color: initial;
    }

    .mega-menu .mega-menu-list > li,
    .mega-menu .mega-menu-list li {
        padding: 0;
        margin: 0;
        position: relative;
    }

    .mega-menu .mega-menu-list li a,
    .mega-menu .mega-menu-list.red-dot > li > a,
    nav .menu .sub-menu .sub-menu-link {
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        letter-spacing: 0;
        width: initial;
        text-transform: uppercase;
        font-weight: normal;
        padding: 8px 50px 8px 0;
        display: block;
        border-bottom: 1px solid #cccccc;
    }

    .mega-menu .mega-menu-list > li > ul {
        margin: 0;
        display: none;
    }

    .mega-menu .mega-menu-list a {
        padding-left: 25px;
    }

    .dropdown-btn ~ ul {
        position: initial !important;
        left: initial !important;
        right: initial !important;
        top: initial !important;
        border: 0 !important;
        box-shadow: initial !important;
        padding-left: 15px;
    }

    .mega-menu {
        position: initial !important;
        left: initial !important;
        right: initial !important;
        top: initial !important;
        border: 0 !important;
        box-shadow: initial !important;
        padding: initial !important;
        background-color: initial !important;
    }

    .mega-menu h5 {
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        letter-spacing: 0;
        position: relative;

        padding: 5px 40px 5px 15px;
        display: block;
        border-bottom: 1px solid #7a4e7b;
        color: #fff;
    }

    .mega-menu-banner {
        display: none;
    }

    .mega-menu-list {
        margin: 0 0 0 0;
    }

    .delivery-hint span {
        display: block;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
    }

    .delivery-hint .sep {
        display: none;
    }

    .delivery-hint {
        padding: 4px 0;
    }

    .top .top-item {
        text-align: center;
        display: inline-block;
    }

    .top .top-item.social {
        margin-right: 0;
        float: right;
    }

    .top .top-item:after {
        display: none;
    }

    .top .top-item.phone {
        display: none;
    }

    .top .top-item.vouchers {
        display: none;
    }

    .langs ul .lang-item {
        margin-right: 20px;
    }

    .get-promo .btn {
        margin: 10px auto 0 auto;
        display: table;
    }

    .get-promo-val .lab {
        margin: 5px 0 5px 0;
    }

    .get-promo-val {
        border: 0;
        margin: 0;
    }

    .get-promo:before {
        bottom: -15px;
        left: inherit;;
        right: -15px;
    }

    .search {
        min-width: inherit;
        max-width: 100%;
        display: none;
        margin: 0 0 15px 0;
    }

    .newsletter .hint {
        padding: 0;
        font-size: 14px;
    }

    .newsletter .btn {
        margin: 10px 0 0 0;
        line-height: 23px;
        height: auto;
    }

    .offers .main-offer a {
        display: table;
        margin: 0 auto;
    }

    .offers .main-offer a.btn {
        position: static;
        margin: 20px auto 0 auto;
    }

    .checkout-modal .btn {
        line-height: 20px;
        padding: 11px 5px;
    }

    .login {
        background: none;
    }

    .countries .country {
        margin: 0 0 10px 0;
        padding: 5px 10px;
    }

    .countries .view-all {
        float: none;
        padding: 5px 0;
        text-align: center;
    }

    .countries {
        margin: 0 0 20px 0;
        border-bottom: 0;
    }

    .variant-error {
        margin: 2px 0 0 0;
    }

    .btn {
        padding: 4px 15px;
        font-size: 16px;
        line-height: 34px;
    }

    .user-menu {
        margin: 10px 0 0 0;
    }

    .alert-success {
        margin-bottom: 0;
        margin-top: 30px;
    }

    .cstm-pagination ul li .page-numbers {
        line-height: 28px;
        font-size: 14px;
        width: 30px;
        height: 30px;
        border-width: 1px;
        margin: 0 3px;
    }


    .checkout {
        padding: 0 0 50px 0;
    }

    footer {
        padding: 0 0 0 0;
    }

    footer .footer-logo {
        height: 70px;
        margin-bottom: 15px;
    }

    footer .footer-contacts li {
        margin-bottom: 20px;
        font-size: 16px;
    }

    footer ul {
        margin-bottom: 35px;
    }

    footer ul li .address-info {
        padding: 0;
    }

    footer ul li a,
    footer ul li .address-info {
        font-size: 14px;
        margin-top: 2px;
    }

    footer h3 {
        margin: 0 0 10px 0;
    }

    footer ul li {
        margin-bottom: 8px;
    }

    footer .copyright {
        padding-top: 20px;
        font-size: 14px;
        margin: 0;
    }

    header.fixed ~ .page {
        padding-top: 58px !important;
    }

    .empty-category img {
        height: 100px;
        margin-top: 12px;
    }

    .category-text p {
        font-size: 14px;
    }

    .contacts:after {
        display: none;
    }

    .contacts .short-map iframe {
        height: 350px;
    }

    .contacts .contact-form {
        border: 0;
        padding: 0;
        margin-top: 0;
    }

    .contacts .contact-info ul li {
        padding: 4px 0 9px 0;
    }

    .contacts .heading {
        margin: 0 0 10px 0;
    }

    .contacts .contact-info {
        padding: 0;
    }

    .contacts h4 {
        font-size: 15px;
        line-height: 22px;
        margin: 0 0 14px 0;
        padding-top: 0;
    }

    .contacts .contact-form p {
        margin: 0 0 25px 0;
    }

    .contacts .contact-form .form-group {
        margin-bottom: 10px;
    }

    .contacts .btn {
        width: 100%;
        min-width: auto;
    }

    #flash-messages-wrapper:empty {
        padding-bottom: 0;
    }

    #wo-breadcrumbs {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        overflow-x: scroll;
        padding-bottom: 7px;
        margin-bottom: -7px;
    }

    .custom-select {
        font-size: 14px;
        min-height: 42px;
        line-height: 26px;
    }

    .carousel-item {
        height: 450px;
    }

    .intro .carousel-caption {
        bottom: 10%;
    }

    #flash-messages-wrapper {
        padding-bottom: 20px;
    }

    #flash-messages-wrapper:empty {
        padding-bottom: 0;
    }

    .products-by-category .category-product-block:hover .hover-info .btn {
        margin-top: -4px;
        min-width: 62px;
        line-height: 23px;
        font-size: 15px;
        padding: 0 8px;
        min-height: 29px;
    }

    .products-by-category .category-product-block h3 {
        font-size: 21px;
        line-height: 40px;
    }

    .products-by-category .category-product-block .hover-info {
        bottom: 8px;
    }

    .products-list .product-item .product-img {
        margin-bottom: 0;
    }

    .page-info ol li {
        font-size: 13px;
    }

    .page-info ol li::after {
        right: -13px;
        line-height: 16px;
    }

    h1 {
        font-size: 32px;
        line-height: 34px;
        margin: 0 0 25px 0;
    }

    .order-auth-login {
        padding: 15px 15px 0 15px;
        margin-bottom: 25px;
    }

    .order-auth .heading-icon {
        margin: 0 auto 5px;
        height: 30px;
    }

    .order-auth .heading {
        margin: 0 0 20px 0;
        font-size: 30px;
        line-height: 34px;
    }

    .order-auth-login button,
    .login button,
    .social-buttons .btn,
    .order-auth-reg .btn {
        padding: 3px 15px;
        line-height: 34px;
        min-height: 42px;
    }

    .order-auth-login .social-buttons {
        margin-bottom: 6px;
    }

    .order-auth-reg {
        padding: 25px 15px;
    }

    .register .exist-reg {
        margin: 20px 0 0 0;
    }

    .order-auth-login .forgot-password-link,
    .login .forgot-password-link {
        font-size: 14px;
    }

    .thankyou .thankyou-cups {
        margin: 0 auto 30px;
        width: 80px;
    }

    .thankyou .total {
        margin-top: 30px;
    }

    .thankyou .payment-bank {
        margin-top: 25px;
    }

    .btn-back {
        font-size: 14px;
    }

    .contact-page:after {
        display: none;
    }

    .about-us-desc h5 {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 15px;
    }

    .about-us .hero-text {
        min-height: 175px;
        margin-top: -21px;
    }

    .about-us .hero-text h1 {
        font-size: 36px;
        line-height: 44px;
    }

    .our-showroom .gallery-block {
        flex-wrap: wrap;
    }

    .our-showroom .gallery-block .col-block {
        padding: 0 5px;

    }

    .our-showroom .gallery-block .col-block .img-block {
        margin-bottom: 10px;
    }

    .our-showroom .gallery-block .col-block .img-block img {
        width: 135px;
    }

    .our-showroom .gallery-block .col-block .img-block.two-row:first-child {
        margin-right: 6px;
    }

    .our-showroom .gallery-block .col-block .img-block.two-row:last-child {
        margin-left: 6px;
    }

    .back {
        font-size: 14px;
    }


    .profile #flash-messages-container {
        background: transparent;
        margin: 0;
    }

    .error-page {
        padding: 0;
    }

    .error-page .error-code {
        font-size: 80px;
        line-height: 53px;
    }

    .error-page .error-text {
        margin: 26px 0 10px 0;
        font-size: 17px;
    }

    .review-modal .review-info select.form-control {
        margin-left: 5px;
    }

    .add-review .review-info {
        padding: 30px 15px;
    }

    .add-review .product-rate > ul {
        display: block;
        margin-left: 0;
    }

    .add-review .product-rate > .product-stars {
        display: inline-block;
        margin: 0 0 0 0;
        vertical-align: text-bottom;
        position: relative;
    }

    .add-review input[type="radio"]:checked + label:before,
    .add-review input[type="radio"]:not(:checked) + label:before {
        margin-left: 0;
    }

    .add-review label {
        font-size: 14px;
    }

    .add-review .review-img {
        margin: 30px auto 30px;
        display: block;
    }

    .add-review .modal-header h5 {
        font-size: 20px;
        line-height: 24px;
    }

    .add-review label, .add-review legend {
        font-size: 16px;
    }

    .add-review .lab-no,
    .add-review .lab-yes {
        font-size: 15px;
    }

    .add-review .btn {
        width: 100%;
    }

    .add-review .form-group {
        margin-bottom: 20px;
    }

    /*comments*/
    .comments .rating {
        margin-bottom: 12px;
        text-align: center;
    }

    .comments .rating .lab {
        margin: 0;
    }

    .comments .rating .rate {
        font-size: 32px;
    }

    .comments .rate-list .btn {
        margin: 0 0 25px auto;
        width: 100%;
    }

    .comments .rate-list {
        margin: 0 0 15px 0;
    }

    .comments .comment-row {
        padding: 20px 0 25px 0;
    }

    .comments .comment-row .comment-author,
    .comments .comment-row .comment-heading {
        font-size: 24px;
        line-height: 26px;
        margin-top: 0;
    }

    .comments .comment-text,
    .comments .comment-row .wine-recommended {
        font-size: 14px;
    }

    .sort.active .sort-result {
        margin: 15px 0 18px 0;
    }

    .sort-result .form-group {
        margin-bottom: 6px;
    }

    .sort .results-count {
        padding: 6px 12px;
        font-size: 14px;
        line-height: 20px;
        display: inline-block;
    }

    .faq .faq-category h3 {
        font-size: 26px;
        line-height: 30px;
    }

    .faq .question {
        padding: 13px 26px 13px 0;
    }

    .faq .answer .answer-content {
        padding: 4px 0 20px;

    }

    .search .search-results .found {
        font-size: 15px;
    }

    .search .search-results .result-row .product-title {
        font-size: 14px;
    }

    .order-auth-login .icon-mail,
    .login .icon-mail {
        top: 14px;
    }

    .order-auth-login .icon-password, .login .icon-password,
    .register .icon-password {
        top: 12px;
    }

    .footer-payments {
        margin-bottom: 0;
    }

    .footer-payments li {
        margin-bottom: 0;
    }

    footer .copyright-text {
        margin: 5px 0;
        text-align: left;
    }

    footer .footer-social {
        text-align: left;
        margin: 0;
    }

    footer .footer-social li {
        margin: 0 20px 0 0;
    }
}

@media screen and (orientation: landscape)
and (min-device-width: 0)
and (max-device-width: 767px) {

    .top .top-item.social {
        float: none;
        margin-right: 35px;
    }

    .top .top-item.phone {
        display: inline-block;
    }

    .delivery-hint .sep {
        display: inline-block;
    }

    .owl-carousel .owl-stage {
        padding-left: 0 !important;
    }

    .modal-content {
        padding: 16px 15px 20px 15px;
    }

    .checkout-modal .hint {
        font-size: 16px;
    }

    .checkout-modal .icn {
        margin: 20px 0 25px 0;
        height: 80px;
    }

    .checkout-modal .btn {
        font-size: 18px;
    }
}

@media screen and (orientation: landscape)
and (min-device-width: 768px)
and (max-device-width: 1024px) {

    .space {
        padding: 65px 0;
    }

    .intro h2 {
        font-size: 34px;
        line-height: 44px;
    }

    .categories .products-list .product-item {
        margin-bottom: 20px;
    }
}


@media (max-height: 640px) and (max-width: 991px) {
    header .menu {
        height: 100vh;
        padding-bottom: 55px;
    }
}

@media (min-width: 576px) {
    .modal-dialog.checkout-modal-dialog {
        max-width: 530px;
    }

}

@media (min-width: 0px) and (max-width: 575px) {
    .our-showroom .gallery-block .col-block {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .our-showroom .gallery-block .col-block .img-block img {
        width: 100%;
    }

    .our-showroom .gallery-block .col-block:last-child,
    .our-showroom .gallery-block .col-block.last-block {
        flex: 100%;
        max-width: 100%;
    }
}