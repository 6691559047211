/* base styles

================================================== */

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

body,
html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    color: #000000;
    font-size: 16px;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif;
}

::selection {
    background: #009444;
    color: #fff;
}

::-moz-selection {
    background: #009444;
    color: #fff;
}

:focus {
    outline: 0
}

img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    border: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

.space {
    padding: 70px 0;
}

.space-bot {
    padding-bottom: 70px;
}

/* typography
================================================== */
h1, h2, h3, h4, h5, h6 {
    color: #231f20;
}

h1 {
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
}

h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
}

h3 {
    font-size: 26px;
    line-height: 32px;
    font-weight: bold;
}

h4 {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
}

h5 {
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
}

h6 {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
}

strong {
    font-weight: 700;
}

a {
    color: #231f20;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

a:hover,
a:active,
a:focus {
    color: #009444;
    outline: 0;
    text-decoration: none;
}

p {
    color: #6d6e71;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.5;

}

.heading {
    margin: 0 0 50px 0;
    font-size: 26px;
    line-height: 32px;
    color: #40414E;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
}

.btn {
    font-size: 16px;
    line-height: 42px;
    padding: 0 30px;
    min-height: 44px;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    min-width: 200px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn-primary {
    background-color: #009444;
    border-color: #009444;
    color: #fff;
}

.btn-primary:hover {
    background-color: #ffffff;
    border-color: #009444;
    color: #009444;
}

.btn-success:hover,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    background-color: #fff;
    border-color: #28a745;
    color: #28a745;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: #009444;
    border-color: #009444;
    color: #fff;
    box-shadow: none;
}

.btn-dark {
    background: #303539;
    border-color: #303539;
    color: #ffffff;
}

.btn-dark:hover {
    background: #fff;
    border-color: #303539;
    color: #303539;
}

.btn-outline-dark {
    background: #ffffff;
    border-color: #303539;
    color: #303539;
}

.btn-outline-dark:hover {
    background: #303539;
    color: #ffffff;
}

.btn-secondary {
    background-color: #009444;
    border-color: #009444;
    color: #fff;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
    background: #102A85 !important;
    border-color: #102A85;
}

.btn-secondary-outline {
    background-color: #fff;
    border-color: #009444;
    color: #009444 !important;
}

.btn-secondary-outline:hover {
    border-color: #009444;
    background-color: #009444;
    color: #fff !important;
}

.btn-outline {
    background-color: transparent;
    border-color: #009444;
    color: #009444;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
    border-color: #009444;
    background-color: #009444;
    color: #fff;
}

.button-outline-primary {
    color: #059EA5;
    border-color: #059EA5;
    background: #fff;
}

.btn .btn-icons img {
    width: 22px;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.btn .btn-icons {
    position: relative;
    top: 5px;
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-left: 5px;
}

.btn .btn-icons .hover-img {
    opacity: 0;
}

.btn:hover .btn-icons .hover-img {
    opacity: 1;
}

.btn:hover .btn-icons .show-img {
    opacity: 0;
}

.button-outline-primary:hover,
.button-outline-primary:focus {
    background: #059EA5;
    color: #fff;
}

.btn-outline-secondary {
    color: #fff !important;
    border-color: #fff;
    background: transparent;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active {
    background: #009444;
    border-color: #009444;
}

.invalid-feedback {

}

.container {
    max-width: 1360px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
}