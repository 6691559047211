
.sidebar .active-filters h4 {
    margin: 0 0 20px 0;
    position: relative;
}

.sidebar h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 30px 0;
    position: relative;
    border-bottom: 1px solid #8d8f92;
    padding: 25px 0 15px;
}

.sidebar h3 img {
    margin-top: -2px;
}

.sidebar .active-filters {
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}

.sidebar .active-filters ul li {
    margin: 0 0 5px 0;
}

.sidebar .active-filters .filter-type {
    font-size: 12px;
    display: inline-block;
}

.sidebar .active-filters .filter-value {
    padding: 3px 5px;
    border: 1px solid #e3e2e2;
    text-align: center;
    color: #303539;
    transition: all .4s;
    display: inline-block;
    font-size: 12px;
    margin: 3px 0 0 5px;
}

.sidebar .active-filters .filter-value span {
    position: relative;
    top: -1px;
    cursor: pointer;
}

.sidebar .active-filters .filter-value span img {
    height: 10px;
    margin-left: 5px;
}

.sidebar .active-filters .filter-value span .icn {
    display: inline-block;
}

.sidebar .active-filters .filter-value span:hover .icn {
    display: none;
}

.sidebar .active-filters .filter-value span .icn-hover {
    display: none;
}

.sidebar .active-filters .filter-value span:hover .icn-hover {
    display: inline-block;
}

.sidebar .active-filters .filter-value:hover {
    border-color: #303539;
}

.sidebar.active .widgets {
    display: block;
}

.sidebar .widget:first-of-type {
    padding-top: 0;
}

.sidebar .widget {
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
}

.sidebar .widget .widget-box {
    margin: 10px 0 0 0;
    display: none;
}

.sidebar .widget.widget-active .widget-box {
    display: block;
}

.sidebar .widget h4 {
    font-size: 14px;
    line-height: 16px;
    padding-right: 15px;
    text-transform: uppercase;
    color: #40414E;
    font-weight: bold;

    position: relative;
    cursor: pointer;
}

.sidebar .widget h5 {
    font-size: 18px;
    line-height: 20px;
    padding: 3px 0;
    color: #222;

    position: relative;
    cursor: pointer;
}

.sidebar .widget .category-icon {
    font-size: 16px;
    margin-right: 5px;
}

.sidebar .widget.widget-active h4:after {
    transform: rotate(-90deg);
}

.sidebar .widget h4:after {
    content: ">";
    top: -2px;
    right: 0;
    position: absolute;
    font-size: 16px;
    font-family: cursive;
}

.sidebar .widget .aside-dropdown > li {
    position: relative;
    padding: 0 0 0 15px;

}

.sidebar .widget .aside-dropdown > li.dd-menu ul {
    display: none;
}

.sidebar .widget .aside-dropdown > li.dd-menu:after {
    content: "\f105";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .widget .aside-dropdown > li.dd-menu-active ul {
    display: block;
}

.sidebar .widget .aside-dropdown > li.dd-menu-active:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
}

.sidebar .widget .aside-dropdown > li:last-of-type {
    border: 0;
}

.sidebar .widget .aside-dropdown > li > a {
    padding: 3px 0;
    display: block;
    font-size: 14px;
    line-height: 18px;
}

.sidebar .widget .aside-dropdown > li > a:hover {
    color: #009444;
}

.sidebar .widget .aside-dropdown > li > ul {
    padding: 0 0 15px 0;
}

.sidebar .widget .aside-dropdown > li > ul > li > a {
    padding: 3px 15px 3px 10px;
    display: block;
    line-height: 20px;
    font-size: 15px;
}

.sidebar .widget .aside-dropdown > li > ul > li > a:hover {
    color: #009444;
}

.sidebar .widget .sort-search {
    position: relative;
    margin: 0 0 20px 0;
}

.sidebar .widget .sort-search img {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 18px;
}

.sidebar .widget .sort-search .form-control {
    font-size: 12px;
    padding-left: 35px;
}

.sidebar .widget .filter-list {
    max-height: 200px;
    overflow-x: auto;
    position: relative;
}

.sidebar .widget .filter-list .filter-item {
    padding: 0 0 0 28px;
    position: relative;
    margin: 10px 0;
}

.sidebar .widget .filter-list .filter-item a {
    color: #333333;

    font-size: 14px;
    -webkit-appearance: none;
}

.sidebar .widget .filter-list .filter-item a:hover {
    color: #009444;
}

.sidebar .widget .filter-list .filter-item.selected a {
    color: #009444;
}

:root{
    --after-bg-color: #40414E;
    --before-bg-color: transparent;
}
.sidebar .widget .filter-list .filter-item.selected:after {
    content: "";
    background: url(../../images/icons/check.svg) center center no-repeat, #40414E;
    background-size: 12px;
    background-color:var(--after-bg-color);
    position: absolute;
    top: 3px;
    left: 0;
    width: 20px;
    height: 20px;
}

.sidebar .widget .filter-list .filter-item:before {
    content: "";
    width: 18px;
    height: 18px;
    border: 1px solid #CCCCCC;
    display: block;
    position: absolute;
    background-color:var(--before-bg-color);
    top: 4px;
    left: 0;
}

.extra-controls {
    margin: 14px 0 0 0;
    position: relative;
}

.extra-controls:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 1px;
    background: #333333;
    margin-left: -2px;
}

.extra-controls input {
    width: 100%;

    height: 40px;
    padding: 0 10px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #CCCCCC;
    font-size: 14px;
}

.extra-controls input:focus {
    box-shadow: none;
    border-color: #009444;
}

aside .clear-all {
    display: inline-block;
    float: right;
}

aside .clear-all a {
    color: #333333;
    font-size: 12px;
    text-transform: initial;
    font-weight: normal;
}

aside .clear-all a:hover {
    color: #009444;
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    aside.sidebar {
        width: 200px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .sidebar .widget h4:after {
        font-size: 16px;
        top: 0;
    }

    .sidebar .active-filters h4 {
        margin: 0 0 10px 0;
    }

    .sidebar .active-filters {
        padding: 0 0 20px;
    }

    .sidebar .active-filters ul li {
        display: inline-block;
        margin: 0 10px 3px 0;
    }

    .sidebar .widget {
        padding: 10px 0;
    }

    .sidebar.active .widgets {
        display: none;
    }

}