
.top {
    background-color: #F8F9F9;
    padding: 10px 0 0 0;

    font-size: 14px;
}

.top .top-item {
    display: inline-block;
    position: relative;
    margin-right: 30px;
}

.top .top-item:last-child {
    margin-right: 0;
}

.top .top-item a img {
    margin-right: 5px;
    margin-top: -4px;
}

.top .top-item.phone a {
    font-weight: 500;
    display: block;
    padding-bottom: 10px;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    opacity: 1;
}

/*langs*/
.langs ul .lang-item {
    display: inline-block;
    position: relative;
    margin-right: 30px;
}

.langs ul .lang-item:last-child {
    margin-right: 0;
}

.langs .lang-icon {
    margin-right: 10px;
}

.langs ul .lang-item .lang-item-link .nav-arrow {
    margin-left: 2px;
}

.langs ul .lang-item .lang-item-link {
    color: #4E5757;
    display: block;
    position: relative;
    padding-bottom: 10px;
}

.langs ul > .lang-item:hover > .sub-lang {
    display: block;
}

.langs ul .sub-lang:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #009444;
}

.langs ul.sub-lang {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: auto;
    margin-top: 0;
    padding: 8px 0;
    display: none;
    min-width: 102px;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.langs ul.sub-lang li:hover > ul.sub-lang {
    display: block;
}

.langs ul.sub-lang li > ul.sub-lang {
    top: 0;
    left: 0;
    display: none;
}

.langs ul .sub-lang .sub-lang-item {
    display: block;
    position: relative;
}

.langs ul .sub-lang .sub-lang-item-link {
    display: block;
    position: relative;
    padding: 5px 20px;
}

.langs ul .sub-lang .sub-lang-item-link:hover {
    color: #009444;
}

/*social*/
.social ul li {
    display: inline-block;
    margin: 0 5px;
}

.social ul li a {
    color: #fff;
}

.social ul li a:hover {
    opacity: .8;
}

/*delivery hint*/
.delivery-hint {
    background-color: #6a6665;
    padding: 8px 0;
    text-align: center;
    color: #eaeaea;
}

.delivery-hint span {
    margin: 0 10px;
    opacity: .7;
}

.category-text {
    scroll-margin-top: 200px;
    scroll-snap-margin: 200px;
    margin: 0 0 30px 0;
}

.category-text,
.category-text p {
    font-size: 15px;
    text-align: justify;
    color: #5A5E5E;
}

.error-page {
    text-align: center;
    padding: 0 50px;
}

.error-page .error-code {
    font-size: 110px;
    line-height: 74px;
    font-weight: bold;
    margin: 0 0 18px 0;
    color: #40414E;

}

.error-page .error-text {
    margin: 35px 0 13px 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    color: #40414E;
}

/*header*/
header {
    padding: 22px 0 0 0;
    background-color: #fff;
    z-index: 105;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

header.fixed ~ .page {
    padding-top: 128px !important;
}

header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0 0 0;
}

header.fixed .logo img {
    height: 50px;
}

header.fixed nav {
    margin: 5px auto 15px;
}

header.fixed .mega-menu {
    top: 66px;
}

header .logo img {
    height: 60px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

/*search*/
.search {
    position: relative;
    min-width: 400px;
    margin: 9px auto;
    display: table;
}

.search.open {
    display: block;
}

.search input[type=text] {
    border: 0;
    padding: 10px 38px 10px 15px;
    width: 100%;
    border-bottom: 1px solid #CCCCCC;

    font-weight: 500;
    font-size: 14px;
}

.search button {
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 15px;
    top: 10px;
    color: #d9d9d9;
    outline: none;
}

.search .search-results {
    overflow: auto;
    position: relative;
    padding: 10px 0;
}

.search .search-container {
    position: absolute;
    top: 43px;
    padding: 0 15px;

    max-height: 80vh;
    overflow-x: auto;
    z-index: 4;
    background-color: #fff;
    width: 100%;
    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}


.search .search-results .result-row {
    padding: 10px 12px;
    background-color: #fff;
    border-top: 0;
    text-align: left;
    border-bottom: 1px solid #CCCCCC;
}

.search .search-results .result-row:last-of-type {
    border-bottom: 0;
}

.search .search-results .result-row .product-title {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    color: #40414E;
    display: inline-block;

}

.search .search-results .result-row .product-title:hover {
    color: #009444;
}

.search .all-results {
    text-align: center;
    display: block;
    font-size: 12px;
    padding: 10px 0;
    border-top: 1px solid #CCC;
    color: #868695;

}

.search .all-results:hover {
    color: #009444;
}

.search .no-results {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #CCCCCC;
    color: #868695;

    font-size: 12px;
}

.ps > .ps__rail-x,
.ps > .ps__rail-y {
    opacity: 0.6;
}

/*menu*/
nav {
    margin: 5px auto 15px;
    display: table;
}

nav .menu .menu-item {
    display: inline-block;
    padding: 0 15px;
    position: relative;
}

nav .menu .menu-item:first-of-type:after {
    content: "";
    position: absolute;
    top: 0;
    right: -3px;
    background-color: #ccc;
    display: block;
    width: 1px;
    height: 100%;
}

nav .menu .menu-item.active .menu-link:before {
    display: block;
    background-color: #4e1e50;
}

nav .menu .menu-item:hover .menu-link:before {
    width: 100%;
}

nav .menu .menu-item .menu-link:before {
    content: "";
    width: 0;
    transition: all .3s;
    height: 2px;
    position: absolute;
    top: 38px;
    left: 0;
    background-color: #009444;
    display: block;
    z-index: 160;
}

nav .menu .menu-item.rel {
    position: relative;
}

nav .menu .menu-item .menu-link {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000;
    position: relative;
    font-weight: bold;
    display: inline-block;
}

nav .menu .menu-item .dropdown-btn {
    margin-left: 10px;
    display: inline-block;
}

nav .menu .menu-item.active .menu-link {
    color: #4e1e50;
}

nav .menu .menu-item .menu-link.featured {
    color: #F0544F;
}

nav .menu .menu-item:hover .menu-link,
nav .menu .menu-item.offer-special .menu-link {
    color: #009444;
}

nav .menu .menu-item .menu-link:hover:after,
nav .menu .menu-item.offer-special .menu-link:after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);

    -moz-opacity: 100;
    -khtml-opacity: 100;
    opacity: 100;
    opacity: 1;
}

nav .menu .sub-menu {
    background-color: #fff;
    left: -20px;
    right: auto;
    position: absolute;
    top: 34px;
    padding: 20px 0 8px 0;
    z-index: 102;
    display: none;

    -webkit-box-shadow: 3px 18px 24px rgba(0, 0, 0, .1);
    -moz-box-shadow: 3px 18px 24px rgba(0, 0, 0, .1);
    box-shadow: 3px 18px 24px rgba(0, 0, 0, .1);
}

header.fixed nav .menu .sub-menu {
    top: 39px;
}

header.fixed nav .menu .menu-item .menu-link:before {
    top: 38px;
}

nav .menu .sub-menu li:hover > ul.sub-menu {
    /*display: block;*/
}

nav .menu .sub-menu li > ul.sub-menu:before {
    display: none;
}

nav .menu .sub-menu li > ul.sub-menu {
    top: 0;
    left: 100%;
    right: auto;
    display: none;
}

nav .menu .sub-menu .sub-menu-item {
    display: block;
    position: relative;
}

nav .menu .sub-menu-arrow {
    position: absolute;
    right: 16px;
    top: 10px;
}

nav .menu .sub-menu .sub-menu-link,
nav .mega-menu ul li a {
    display: block;
    position: relative;
    font-size: 16px;
    color: #000;
    line-height: 22px;
    text-align: left;
    width: 230px;
    margin: 0;
    padding: 12px 20px;
    text-transform: uppercase;
}

nav .menu .sub-menu .sub-menu-link:hover,
nav .mega-menu ul li a:hover {
    color: #009444;
}

.mega-menu {
    position: absolute;
    display: none;
    top: 75px;
    left: 0;
    width: 93%;
    background-color: #fff;
    z-index: 155;
    padding: 20px 20px 40px;
    border-top: 1px solid #d7d7d7;
    box-shadow: 3px 18px 24px rgba(0, 0, 0, .1);
}

.mega-menu h5 {
    margin: 0 0 15px 0;
    color: #ababab;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
}

.mega-menu .mega-menu-container {
    margin: 0 auto;
    display: table;
    width: 100%;
    max-width: 1300px;
}

.mega-menu .mega-menu-list li {
    margin: 4px 0;
}

.mega-menu .mega-menu-list li a {
    font-size: 13px;
}

.mega-menu .mega-menu-list > li {
    position: relative;
    padding: 0 0 0 15px;
}

.mega-menu .mega-menu-list.red-dot > li:before {
    content: "";
    background-color: #009444;
    position: absolute;
    top: 6px;
    left: 0;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 100%;
}

.mega-menu .mega-menu-list.red-dot > li > a {
    color: #009444;
    font-weight: bold;
}

.mega-menu .mega-menu-list > li > a:hover {
    color: #009444;
}

.mega-menu .mega-menu-list > li > ul {
    margin: 0 0 20px 0;
}

.mega-menu-list {
    margin: 0 0 20px 0;
}

/*intro*/
.intro h2 {
    margin: 0 0 30px 0;
}

.intro .btn {
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 0 45px;
}

.intro .carousel-caption {
    bottom: 15%;
}

.intro .carousel-indicators {
    bottom: 20px;
    z-index: 5;
    align-items: flex-end;
}

.intro .carousel-item {
    background-size: contain;
}

.products {
    padding-bottom: 100px;
}

.products .hero-area {
    padding: 50px 0;
}

.products .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
}

.products .hero-area p {
    color: #fff;
}

.products .hero-area a {
    color: #fff;
    text-decoration: underline;
}

.sort h3 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sort.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sort h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sort.active .sort-result {
    display: block;
}

.sort-result {
    padding: 0 0 10px 0;
}

.sort .heading {
    margin: 0;
}

.sort-result .form-group {
    margin: 0;
}

.sort .sort-result {
    margin: 0 0 30px 0;
    display: none;
    border-bottom: 1px solid #8d8f92;
}

.sort .results-count {
    line-height: 44px;

    font-weight: 500;
    background: #F8F9F9;
    padding: 6px 12px;
    font-size: 15px;
}

.countries h3 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 20px 0;
    position: relative;
}

.countries.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.countries h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.countries.active .countries-result {
    display: block;
}

.countries .countries-result {
    display: none;
}

.countries {
    margin: 0 0 30px 0;
}

.countries .view-all {
    text-align: center;
    padding: 11px 0;
}

.countries .view-all a {
    color: #afafaf;
    cursor: pointer;
    text-decoration: underline;
}

.countries .view-all a:hover {
    color: #009444;
}

.countries .clear-filter {
    padding: 11px 0;
    text-align: left;
}

.countries .clear-filter button img {
    height: 20px;
    position: relative;
    top: -1px;
    margin-right: 1px;
}

.countries .clear-filter button {
    color: #afafaf;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: 0;
    transition: all .3s;
}

.countries .clear-filter button:hover {
    color: #009444;
}

.countries h3 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
}

.countries .country {
    padding: 10px;
    border: 1px solid #e3e2e2;
    margin: 0 0 20px 0;
    text-align: center;
    color: #009444;
    cursor: pointer;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.countries .country.active {
    background-color: #009444;
    color: #fff;
    border-color: #009444;
}

.countries .country:hover {
    border-color: #009444;
}

.variant-error {
    margin: 2px 0 0;
    font-size: 12px;
    color: #B8801E;
    width: 100%;
}

.variant-error i {
    margin-right: 3px;
    font-size: 14px;
    position: relative;
}

.signature {
    margin: 10px 0 0 0;
}

.signature img {
    height: 28px;
    width: inherit !important;
}

/*newsletter*/
.newsletter {
    position: relative;
    background: url("../../images/newsletter-ngr.jpg") center center no-repeat;
    background-size: cover;
}

.form-check {
    position: initial;
    display: initial;
    padding: initial;
}

.newsletter .container {
    position: relative;
    z-index: 3;
}

.newsletter .heading {
    margin-bottom: 8px;
    text-align: left;
    color: #fff;
}

.newsletter .form-control {
    margin: 0 0 10px 0;
}

.newsletter .btn {
    height: 46px;
    line-height: 36px;
}

.newsletter .checkbox label {
    color: #fff;
}

.newsletter .hint {
    font-weight: 300;
    font-size: 16px;
    color: #fff;
}

.back {
    margin: 15px 0 0 0;
    display: inline-block;
    font-size: 15px;
    text-decoration: underline;
}

.back:hover {
    text-decoration: none;
}

.back i {
    margin-right: 5px;
}

.login .space, .register .space {
    padding: 50px 0;
}

.social-buttons .btn {
    width: 100%;
    margin: 5px 0 10px 0;
    position: relative;
    padding: 1px 15px;
}

.social-buttons .btn .fa {
    font-size: 22px;
    margin-right: 8px;
    position: relative;
    top: 2px;
}

.social-buttons .btn.facebook {
    background: #415a94;
    color: #fff;
}

.social-buttons .btn.facebook:hover {
    background: #314471;
    color: #fff;
}

.social-buttons .btn.google {
    background: #cf553d;
    color: #fff;
}

.social-buttons .btn.google:hover {
    background: #ae412b;
}

.social-buttons .btn.apple {
    background: #000;
    color: #fff;
}

.social-buttons .btn.apple:hover {
    background: #333333;
}

.order-auth-login .forgot-password-link,
.login .forgot-password-link {
    float: right;
    text-decoration: underline;
    color: #868695;
    transition: all .3s;
}

.order-auth-login .forgot-password-link:hover,
.login .forgot-password-link:hover {
    color: #009444;
    text-decoration: none;
}

.order-auth-login button,
.login button {
    display: block;
    width: 100%;
    padding: 1px 15px;
}

div.or {
    color: #7F8282;
}

.login .new-reg {
    text-align: center;
    margin: 40px 0 0 0;
}

.login .new-reg span {
    display: block;
}

.login .new-reg a {
    display: inline-block;
    text-decoration: underline;
    color: #969595;
    margin: 2px 0 0 0;
}

.login .new-reg a:hover {
    text-decoration: none;
    color: #009444;
}

.order-auth-login {
    padding: 50px 25px 0 25px;
}

.order-auth-reg {
    padding: 50px 25px;
    background-color: #F0FAFA;
    height: 100%;
}

.order-auth-reg .or {
    text-align: center;
    margin: 11px 0;
}

.order-auth-reg p {
    text-align: center;
    margin-bottom: 11px;
}

.order-auth-reg .btn {
    display: block;
    width: 100%;
    padding: 1px 15px;
}

.order-auth .heading {
    margin: 0 0 35px 0;
    font-size: 34px;
    line-height: 36px;
}

.order-auth .heading-icon {
    margin: 0 auto 8px;
    display: block;
    height: 34px;
}

/*footer*/

.delivery-benefits {
    background-color: #fbfbfb;
    padding: 30px 0;
    margin: 0 0 50px 0;
}

.delivery-benefits img {
    width: 40px;
    height: 40px;
}

.delivery-benefits .lab {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
}

footer {
    background-color: #fff;
    content-visibility: auto;
    position: relative;
    z-index: 3;

}

footer h3 {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 25px 0;
    text-transform: uppercase;
}

footer .footer-menu li {
    display: block;
    margin: 10px 0;
}

footer .footer-menu li a {
    display: block;
}

footer .footer-social {
    text-align: right;
}

footer .footer-social li {
    display: inline-block;
    margin: 0 0 0 20px;
}

footer .footer-social li a {
    font-size: 24px;
}

footer .copyright {
    background-color: #fbfbfb;
    padding: 20px 0;
    margin: 25px 0 0 0;
}

footer .copyright-text {
    text-align: center;
}

.cstm-pagination {
    margin: 20px auto 0 auto;
    display: table;
}

.cstm-pagination ul {
    font-size: 0;
}

.cstm-pagination ul li {
    display: inline-block;
    font-size: 12px;
}

.cstm-pagination ul li .page-numbers {
    font-size: 16px;
    line-height: 36px;
    display: block;
    width: 38px;
    height: 38px;
    text-align: center;
    margin: 0 6px;
    border: 1px solid #CCCCCC;
    border-radius: 100%;

}

.cstm-pagination ul li .page-numbers:hover {
    border: 1px solid #40414E;
    color: #40414E;
}

.cstm-pagination ul li.active .page-numbers {
    border: 1px solid #40414E;
    background-color: #40414E;
    color: #fff;
}

.ps__thumb-y {
    border-radius: 0;
}

.ps .ps__rail-y,
.ps .ps__rail-y {
    background-color: #eee;
    opacity: 0.6;
    width: 8px;
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-y.ps--clicking {
    background-color: #eee;
    opacity: 0.9;
    width: 15px;
}

.owl-stage {
    padding-left: 0 !important;
}

/* ============================================= */

.thankyou h1 {
    margin: 0 0 35px 0;
}

.thankyou .thankyou-cups {
    margin: 0 auto 35px;
    display: block;
    width: 135px;
}

.thankyou p {
    font-weight: 300;
}

.thankyou .total {
    margin-top: 42px;
}

.thankyou .payment-bank {
    margin-top: 67px;
}

.thankyou .payment-bank h3 {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 30px;
}

.thankyou .payment-bank ul li:not(:last-child) {
    margin-bottom: 12px;
}


.thankyou .payment-bank .lab {
    color: #7F8282;
    font-weight: 500;
    font-size: 15px;
}

.thankyou .payment-bank .val {
    color: #37394A;
    font-weight: 500;
}

.btn-back {
    color: #868695;
    font-weight: 300;
    display: table;
    margin: 0 auto;
    text-decoration: underline;
    font-size: 15px;
}

.btn-back:hover {
    text-decoration: none;
    color: #009444;
}

.btn-back img {
    width: 7px;
    margin-right: 4px;
    margin-top: -2px;
}

.reset-password h1 {
    color: #009444;
}

.reset-password h3 {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.reset-password .back {
    margin: 30px auto 0;
    display: table;
}

.reset-password button {
    display: block;
    width: 100%;
}

.register button {
    display: block;
    width: 100%;
}

.register .exist-reg {
    text-align: center;
    margin: 30px 0 0 0;
}

.register .exist-reg span {
    display: block;
}

.register .exist-reg a {
    display: inline-block;
    text-decoration: underline;
    color: #868695;
    margin: 2px 0 0 0;
}

.register .exist-reg a:hover {
    text-decoration: none;
    color: #009444;
}

.forgot-password h3 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin: 0 0 10px 0;

    text-transform: inherit;
}

.forgot-password p {
    font-weight: 300;
    font-size: 15px;
}

.forgot-password .back {
    margin: 30px auto 0;
    display: table;
}

.forgot-password button {
    display: block;
    width: 100%;
}

.card-payment .back {
    margin: 0;
}

.empty-category {
    text-align: center;
}

.empty-category .btn {
    margin: 10px auto 0;
    display: table;
}

.empty-category h3 {
    margin: 0 0 5px 0;
}

.empty-category img {
    margin: 0 auto 12px auto;
    display: block;
    height: 145px;
}

.textpage h3 {
    text-transform: uppercase;
    margin: 0 0 10px 0;
    font-weight: bold;
}

.contact-page {
    position: relative;
}

.contact-page .map {
    position: relative;
    z-index: 2;
    height: 400px;
    width: 100%;
}

.short-map {
    position: relative;
    z-index: 10;
}

.contacts h1 {
    margin: 0 0 20px 0;
}

.contacts h4 {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px 0;
    font-weight: 500;
    padding-top: 8px;
    color: #5A5E5E;

    text-transform: inherit;
}

.contacts .btn {
    min-width: 300px;
}

.contacts .contact-form .form-group {
    margin-bottom: 10px;
}

.contacts .contact-form {
    padding: 0 0 0 50px;
    z-index: 2;
    position: relative;
}

.contacts .contact-form p {
    margin: 0 0 40px 0;
}

.contacts .heading {
    margin: 0 0 20px 0;
}

.contacts .contact-info ul li {
    padding: 10px 0;
}

.contacts .contact-info ul li img {
    margin-right: 15px;
    height: 22px;
}

.contacts .contact-info ul li span {
    font-weight: bold;
}

.contacts .contact-info ul li a {
    color: #009444;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    display: block;
}

.contacts .contact-info ul li span {
    font-weight: bold;
    color: #40414E;
    font-size: 16px;
    line-height: 22px;
    display: block;
}

.contacts .contact-info ul li span img {
    margin: -2px 3px 0 0;
}

.page-info {
    padding: 15px 0;
}

.page-info ol li {
    display: inline-block;
    margin: 0 10px;
    position: relative;
    font-weight: 300;
    color: #868695;
    font-size: 14px;
}

.page-info ol li:first-of-type {
    margin-left: 0;
}

.page-info ol li a {
    text-decoration: none;
    color: #12131D;
    font-weight: 400;
}

.page-info ol li::after {
    content: "/";
    position: absolute;
    top: 0;
    right: -13px;
    font-size: 14px;
    line-height: 20px;
}

.page-info ol li:last-of-type:after {
    display: none;
}

.short-map iframe {
    height: 550px;
}

.short-contacts {
    text-align: center;
}

.short-contacts img {
    margin: 0 0 20px 0;
}

.short-contacts a {
    font-weight: bold;
}

.short-contacts .lab {
    font-weight: bold;
    color: #000;
    margin: 0 0 7px 0;
}

.short-contacts .val {
    padding: 0 50px;
}

.custom-calendar header {
    position: initial;
    padding: 0;
}

.grecaptcha-badge {
    bottom: 95px !important;
}

.newsletter .row .alert {
    width: 100%;
}

#flash-messages-wrapper {
    position: relative;
    float: right;
    width: 100%;
}

#flash-messages-container {
    position: relative;
    z-index: 2 !important;
    width: 100%;
    padding-top: 20px;
}

.alert {

    font-size: 14px;
}

.alert-success {
    margin-bottom: 25px;
}

.alert .close {
    top: 50%;
    right: 0;
    padding: 0.75rem 1.25rem;
    margin-top: -26px;
}

/*register-form*/

.register-form {
    background-size: cover;
    position: relative;
}

.register-form .heading {
    color: #ffffff;
    margin: 0 0 50px 0;
}

.register-form form button {
    display: table;
    margin: 0 auto;
    font-size: 13px;
    letter-spacing: 2px;
}

.register-form form .form-group {
    margin-bottom: 25px;
}

.register-form .form-control {
    height: 50px;
    border: 1px solid #C2C2C2;
}

.register-form form .form-group label {
    color: #ffffff;
}

.register-form .register-link {
    text-align: center;
    color: #ffffff;
    margin: 30px 0 0 0;
}

.register-form .register-link a {
    color: #ffffff;
    margin-left: 10px;
    text-transform: uppercase;
    text-decoration: underline;
}

.register-form .register-link a:hover {
    text-decoration: none;
    color: #009444;;
}

.register-form form .invalid-feedback {
    margin-bottom: 5px
}

nav .menu .menu-item.bottom-menu {
    padding: 18px 15px 18px 15px;
    font-size: 18px;
    font-weight: bold;
}

nav .menu .bottom-menu span {
    display: block;
    text-transform: none;
    font-size: 18px;
    margin-top: 4px;
    line-height: 24px;
}

nav .menu .bottom-menu ul li a {
    display: block;
    font-weight: normal;
    text-transform: none;
    font-size: 16px;
    line-height: 22px;
}

.manufacturers-carousel .owl-item img {
    width: auto;
}

.products-by-category .category-product-block {
    position: relative;
}

.products-by-category .category-product-block:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #40414E;
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
}

.products-by-category .category-product-block:hover:before {
    opacity: 0.7;
}

.products-by-category .category-product-block .hover-info {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
}

.products-by-category .category-product-block:hover .hover-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    bottom: 0;
}

.products-by-category .category-product-block:hover .hover-info .btn {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    min-width: 200px;
}

.products-by-category .category-product-block .hover-info .btn {
    display: none;
}

.offers {
    position: relative;
}

.offers .btn {
    position: absolute;
    bottom: 50%;
    right: 0;
    margin-bottom: -22px;
    margin-right: 60px;
}

button.close {
    text-align: right;
    padding: 0 0 10px 0;
    font-size: 38px;
    font-weight: 500;
    outline: none;
    line-height: 22px;
}

.checkout-modal .btn {
    width: 100%;
    display: block;
}

.checkout-modal .btn.btn-dark {
    margin-bottom: 5px;
}

.checkout-modal .hint {
    margin: 0 0 20px 0;
}

.checkout-modal .icn {
    margin: 0 auto;
    display: block;
    height: 70px;
}

.modal-content {
    padding: 25px 25px 30px 25px;
    border-radius: 0;
}

.about-us .heading {
    text-align: left;
    margin: 0 0 20px 0;
}

.our-showroom {
    margin-bottom: 2px;
}

.our-showroom .gallery-block {
    display: flex;
    justify-content: center;
}

.our-showroom .gallery-block .col-block {
    padding: 12px;
}

.our-showroom .gallery-block .col-block .img-block {
    margin-bottom: 22px;
}

.our-showroom .gallery-block .col-block .img-block.two-row:first-child {
    margin-right: 12px;
}

.our-showroom .gallery-block .col-block .img-block.two-row:last-child {
    margin-left: 12px;
}

.our-showroom .gallery-block .col-block .img-block.sec-images {
    display: flex;
    justify-content: space-between;
}

.splide {
    margin: 0 auto;
}

.thumbnails {
    display: flex;
    margin: 1rem auto 0;
    padding: 0;
    flex-wrap: wrap;
}

.thumbnail {
    overflow: hidden;
    list-style: none;
    margin-top: 10px;
    cursor: pointer;
    opacity: 0.3;
}

.thumbnail.is-active {
    opacity: 1;
}


.thumbnail img {
    width: 100%;
    height: auto;
}

#main-slider {
    max-width: none;
    width: 100%;
    height: 100% !important;
}

/*review-modal*/

.review-modal button.close {
    color: #fff;
    opacity: 1;
}

.review-modal button.close:focus {
    border: 0;
    outline: none;
}

.review-modal .review-info select.form-control {
    display: inline-block;
    width: auto;
    padding: 0 8px;
    margin-left: 10px;
}


.add-review {
    overflow: scroll;
    -webkit-overflow-scrolling: touch
}

.add-review .modal-body {
    padding: 0;
}

.add-review .review-info {
    background-color: #F8F9F9;
    border-left: 1px solid #CCCCCC;
    padding: 30px 40px;
}

.add-review .product-rate {
    padding: 30px 0;
    font-size: 20px;
    font-weight: bold;
    color: #40414E;
}

.add-review .product-rate > ul {
    display: inline-block;
    margin-left: 15px;
    position: relative;
    top: 5px;
}

.add-review .product-rate > ul li {
    display: inline-block;
    margin-right: 7px;
}

.add-review .product-rate > .product-stars {
    position: absolute;
    display: inline-block;
    margin-left: 15px;
}

.add-review .product-rate > .product-stars input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
}

.add-review .product-rate > .product-stars .fa,
.add-review input[type="radio"]:checked + label:before,
.add-review input[type="radio"]:not(:checked) + label:before {
    color: #009444;
    content: "\f006";
    font-size: 34px;
    margin-right: 7px;
    font-weight: normal;
    float: right;
    margin-bottom: 0;
    cursor: pointer;
    position: static;
    padding: 0;
    border: 0;
}

.add-review input[type="radio"]:checked + label:before,
.add-review input[type="radio"]:not(:checked) + label:before {
    margin-top: -1px;
    margin-left: 5px;
    width: 20px;
    height: 15px;
}

.star-rating:hover:before,
.star-rating:hover ~ .star-rating:before,
.star-rating-input:checked ~ .star-rating:before {
    content: "\f005" !important;
    border: 0 !important;
}

.add-review .form-hint {
    color: #40414E;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
}

.add-review .form-group {
    margin-bottom: 30px;
}

.add-review label, .add-review legend {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #40414E;
}

.add-review button {
    margin: 60px auto 0;
    display: table;
}

.add-review .review-img {
    margin: 30px auto 0;
    display: block;
    padding-left: 30px;
}

.add-review .lab-no {
    float: left;
}

.add-review .lab-yes {
    float: right;
}

.add-review .eval {
    margin: 0 0 5px 0;
    display: table;
    width: 100%;
}

.add-review .eval li {
    float: left;
    width: 10%;
}

.add-review .eval li label {
    text-align: center;
    padding: 10px 0;
    background-color: #F8F9F9;
    border: 1px solid #CCCCCC;
    border-right: none;
    cursor: pointer;
    display: block;
    font-size: 14px;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.add-review .eval li label:hover {
    opacity: .7;
    color: #009444;
}

.add-review .eval li input {
    display: none;
}

.add-review .eval li input:checked + label {
    background-color: #009444;
    border-top-color: #009444;
    border-bottom-color: #009444;
    color: #fff;
}

.add-review .eval li input:checked + label:hover {
    opacity: 1;
}

.add-review .eval li:last-of-type label {
    border-right: 1px solid #CCCCCC;
}

.add-review .rec-fr {
    display: table;
    width: 100%;
}

.add-review .rec-fr li {
    float: left;
}

.add-review .rec-fr li label {
    text-align: center;
    padding: 10px 25px;
    background-color: #F8F9F9;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
    cursor: pointer;
    display: block;
    font-size: 14px;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.add-review .rec-fr li label:hover {
    opacity: .7;
    color: #009444;
}

.add-review .rec-fr li input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
}

.add-review .rec-fr li label:before,
.add-review .rec-fr li label:after,
.add-review .eval li label:before,
.add-review .eval li label:after,
.add-review .product-rate > .product-stars .fa:after {
    display: none;
}

.add-review .rec-fr li input:checked + label {
    background-color: #009444;
    border-top-color: #009444;
    border-bottom-color: #009444;
    color: #fff;
}

.add-review .rec-fr li input:checked + label:hover {
    opacity: 1;
}

.add-review .rec-fr li:last-of-type label {
    border-right: 1px solid #CCCCCC;
}

.modal-content {
    border: 0;
    border-radius: 0;
}

.modal-header {
    border-radius: 0;
}

.add-review .modal-header {
    background-color: #009444;
}

.add-review .modal-header h5 {
    color: #fff;
    text-align: center;
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
}

/*comments*/

.comments .rating .lab {
    margin: 16px 0 25px;
    font-size: 15px;
    color: #37394A;
}

.comments .rating .rate {
    font-size: 40px;
    font-weight: bold;
    color: #009444;
}

.comments .rating .count {
    font-size: 12px;
    color: #7F8282;
}

.comments .rate-list {
    margin: 0 0 50px 0;
}

.comments .rate-list .btn {
    margin: 0 0 29px auto;
    display: table;
}

.comments .rate-list ul li {
    margin-bottom: 10px;
}

.comments .rate-list ul li i {
    color: #009444;
    font-size: 16px;
}

.comments .rate-list ul li .val {
    display: inline-block;
    font-size: 14px;
}

.comments .rate-list ul li .rate-progress-bar {
    display: inline-block;
    background-color: #009444;
    height: 5px;
    position: absolute;
}

.comments .rate-list ul li .rate-progress {
    position: relative;
    background-color: #CCCCCC;
    width: 100%;
    height: 5px;
}

.comments .comment-row {
    padding: 35px 0;
    border-bottom: 1px solid #CCCCCC;
}

.comments .comment-row .wine-recommended {
    margin: 15px 0 0 0;
    font-size: 15px;
}

.comments .comment-row .wine-recommended.yes {
    color: #48A56A;
}

.comments .comment-row .wine-recommended.no {
    color: #B8801E;
}

.comments .comment-row .wine-recommended i {
    margin-right: 2px;
}

.comments .comment-row .reviews-count {
    color: #c5c5c5;
    font-size: 12px;
}

.comments .comment-row .comment-author {
    font-size: 26px;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: bold;
    color: #40414E;

    margin-top: -4px;
}

.comments .comment-text {
    color: #40414E;
    font-weight: 300;
    font-size: 15px;
}

.comments .comment-row .comment-heading {
    font-size: 26px;
    line-height: 28px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    font-weight: bold;
    color: #40414E;

}

.comments .comment-row .comment-rate {
    margin: 0 0 15px 0;
}

.comments .comment-row .comment-rate ul {
    display: inline-block;
}

.comments .comment-row .comment-rate ul li {
    display: inline-block;
}

.comments .comment-row .comment-rate ul li i {
    color: #009444;
}

.comments .comment-row .comment-rate span {
    color: #7F8282;
    margin-left: 15px;
    font-size: 12px;
}

.comments .empty-comments {
    text-align: center;
}

.comments .empty-comments img {
    height: 60px;
    margin: 0 0 15px 0;
}

.comments .empty-comments h3 {
    margin: 0 0 3px 0;
}

.comments .comment-btn {
    text-align: center;
    margin: 30px 0 0 0;
}


/*faq*/
.faq .heading {
    margin-bottom: 15px;
}

.faq .faq-hint {
    text-align: center;
    font-weight: bold;
}

.faq .faq-category {
    margin: 0 0 35px 0;
}

.faq .faq-category:last-of-type {
    margin: 0 0 0 0;
}

.faq .faq-category h3 {
    margin: 0 0 15px 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 34px;
    line-height: 38px;
}

.faq .question {
    background-color: #fff;
    padding: 13px 55px 13px 0;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    color: #414141;
}

.faq .question.collapsed {
    box-shadow: none;
    background-color: #fff;
}

.faq .question.active {
    background: #CCCCCC;
}

.faq .accordion {
    margin: 0 0 50px 0;
}

.faq .accordion .accordion-item {
    border-bottom: 1px solid #CCCCCC;
}

.faq .accordion:last-of-type {
    margin: 0;
}

.faq .accordion .question:after {
    content: "-";
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -15px;
    font-size: 20px;
}

.faq .accordion .question.collapsed:after {
    content: "+";
    margin-top: -13px;
}

.faq .answer .answer-content {
    padding: 10px 0 30px;
    color: #40414E;
    font-weight: 300;
    font-size: 15px;
}

.faq .faq-heading {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
    text-transform: uppercase;
}


.banners .big-banner {
    padding: 30px;
    height: 600px;
    background-size: cover !important;
}

.banners .big-banner h2:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 5%;
    height: 1px;
    width: 20%;
    background-color: #fff;
}

.banners .big-banner h2:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 5%;
    height: 1px;
    width: 20%;
    background-color: #fff;
}

.banners .small-banner {
    padding: 30px;
    height: 600px;
    background-size: cover !important;
}

.banners .big-banner h2,
.banners .small-banner h2 {
    color: #fff;
    position: relative;
    text-align: center;
}

.banners .btn {
    margin: 400px auto 0;
    display: table;
}

.banners .small-banner h2:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 5%;
    height: 1px;
    width: 5%;
    background-color: #fff;
}

.banners .small-banner h2:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 5%;
    height: 1px;
    width: 5%;
    background-color: #fff;
}

.extra-controls input {
    padding-right: 30px;
}

.cs-rslider {
    position: relative;
}

.cs-rslider span {
    position: absolute;
    top: 11px;
    right: 10px;
    font-size: 12px;
}

.size-table {
    color: #231f20;
    border: 1px solid #e2e2e2;
    margin: 10px 0 10px 0;
    font-weight: normal;
    letter-spacing: 2px;
}

.sizes-imgs {
    text-align: center;
}

.sizes-imgs img {
    max-height: 350px;
    display: inline-block;
    margin: 0 15px;
}

.modal-sizes .table-sizes {
    margin: 25px 0 0 0;
}

.modal-sizes table tr td:nth-child(1) {
    width: 20%;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}

.modal-sizes table tr th:nth-child(1) {
    text-align: left;
}

.modal-sizes table tr th {
    color: #000;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 10px;
    border-right: 0;
    background: transparent;
    text-align: center;
}

.modal-sizes table tr td {
    text-align: center;
}

.modal-sizes table tr:nth-child(even) {
    background-color: #fff;
}

.modal-sizes table tr:nth-child(odd) {
    background-color: #f2f2f2;
}

.modal-sizes h5 {
    color: #231f20;
    font-size: 28px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 20px 0;
}

.modal-sizes .modal-header .close {
    padding: 0;
}

.choose-or {
    text-align: center;
    margin: 0px 0 20px 0;
    font-weight: bold;
    position: relative;
}

.choose-or:after {
    content: "";
    position: absolute;
    top: 13px;
    left: 15%;
    width: 70%;
    height: 1px;
    background-color: rgba(141, 143, 146, 1);
    z-index: 0;
}

.choose-or span {
    padding: 0 15px;
    background-color: #fff;
    position: relative;
    z-index: 1;
}

.modal-phone-order h5 {
    color: #231f20;
    font-size: 28px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 20px 0;
}

.modal-phone-order p {
    color: #7d7e81;
    text-align: center;
}

.modal-phone-order .btn {
    margin: 0 auto;
    display: table;
}

.phone-order-img {
    margin: 15px auto 30px;
    display: table;
}

.phone-order-img img {
    height: 120px;
    display: block;
}

button.close {
    font-weight: lighter;
    position: absolute;
    top: 5px;
    right: 10px;
}

.select2-container--bootstrap4 .select2-selection {
    font-size: 16px;
    color: #000000;
    border-color: #8d8f92;
    background-color: #fff;
    min-height: 50px;
    font-weight: normal;
    border-radius: 0;
}

.select2-container--bootstrap4.select2-container--focus .select2-selection {
    border-color: #8d8f92;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
    color: inherit;
    min-height: 47px;
    line-height: 47px;
    padding: 0 45px;
}

.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--above {
    border-top: 1px solid #8d8f92;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.select2-search--dropdown .select2-search__field {
    border: 1px solid #8d8f92;
    border-radius: 0;
}

.select2-container--bootstrap4 .select2-dropdown {
    border-color: #8d8f92;
}

.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--below {
    border-radius: 0;
}

.faq-nav ul {
    margin: 0 auto 50px;
    display: table;
}

.faq-nav ul li {
    margin: 0 5px;
    display: inline-block;
}

.faq-nav ul li button {
    color: #231f20;
    border: 1px solid #009444;
    text-align: center;
    display: block;
    padding: 7px 15px 7px 15px;
    min-width: 150px;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    outline: none;
    background-color: transparent;
}

.faq-nav ul li button.active,
.faq-nav ul li button:hover {
    background-color: #009444;
    color: #fff;
}

.faq-nav ul li .faq-icn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50px;
}

.faq-nav ul li span {
    display: block;
}

.faq-nav ul li .faq-icn img {
    max-width: 35px;
    max-height: 35px;
    pointer-events: none;
    position: absolute;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.faq-nav ul li .faq-normal {
    opacity: 1;
}

.faq-nav ul li img.faq-active {
    opacity: 0;
}

.faq-nav ul li button:hover .faq-normal,
.faq-nav ul li button.active .faq-normal {
    opacity: 0;
}

.faq-nav ul li button:hover img.faq-active,
.faq-nav ul li button.active img.faq-active {
    opacity: 1;
}


.history {
    text-align: center;
}

.mission .heading,
.history .heading {
    text-align: center;
}

.company ul li {
    margin: 10px 0;
    display: block;
}

.mission {
    margin: 100px 0;
    padding: 200px 0;
    background: url("../../images/mission-bgr.jpg") center center no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.mission .mission-block {
    padding: 50px;
    background-color: #fff;
    text-align: center;
}

.mission .mission-block .heading {
    margin: 0;
}

.mission .mission-block p {
    color: #009444;
    font-size: 40px;
    line-height: 46px;
    font-weight: bold;
    position: relative;
    margin: 65px 0 0 0;
}

.mission .mission-block p:before {
    content: "";
    background: url("../../images/logo-fragmen.svg") center center no-repeat;
    background-size: 100%;
    position: absolute;
    top: -40px;
    left: 50%;
    width: 60px;
    height: 35px;
    margin-left: -30px;
}


.brand {
    margin: 0 0 100px 0;
}

.brand .heading {
    text-align: left;
}


.border-block-left {
    padding: 50px 40px 30px 100px;
    margin: 0 0 0 -85px;
    z-index: 1;
    position: relative;
}

.border-block-left:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    border: 5px solid #fff;
    z-index: -1;
    box-shadow: 4px 4px 9px 0 rgba(0, 0, 0, .08), 4px 4px 9px 0 rgba(0, 0, 0, .08) inset;

    -webkit-transition: border-color 200ms ease-in 0ms;
    transition: border-color 200ms ease-in 0ms;
}

.border-block-right {
    padding: 50px 100px 30px 40px;
    margin: 0 -85px 0 0;
    z-index: 1;
    position: relative;
}

.border-block-right:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    border: 5px solid #fff;
    z-index: -1;
    box-shadow: 4px 4px 9px 0 rgba(0, 0, 0, .08), 4px 4px 9px 0 rgba(0, 0, 0, .08) inset;

    -webkit-transition: border-color 200ms ease-in 0ms;
    transition: border-color 200ms ease-in 0ms;
}

.similar-products .heading {
    padding: 0 15px;
}

.blog-list .blog-item {
    background-color: rgba(0, 148, 69, 0.084);
    margin: 0 0 50px 0;
}

.blog-list .blog-item .blog-item-img {
    display: block;
}


.blog-list .blog-item-content {
    padding: 50px 30px;
    margin: 0 30px 0 -60px;
    background-color: #fff;
}

.blog-list .blog-item h3 {
    position: relative;
    padding: 0 0 10px 0;
    margin: 0 0 15px 0;
}

.blog-list .blog-item h3:after {
    content: "";
    width: 120px;
    height: 2px;
    background-color: #009444;
    position: absolute;
    left: 0;
    bottom: 0;
}

.blog-list .blog-item-date {
    font-weight: bold;
    margin: 10px 0 13px;
}

.blog-list .blog-item-desc p:last-of-type {
    margin: 0;
}

.blog-filter {
    margin: 0 0 50px 0;
}

.blog-filter li {
    display: inline-block;
    margin-right: 5px;
}

.blog-filter li .btn {
    display: block;
    padding: 0 15px;
    min-width: inherit;
    line-height: 34px;
    min-height: 36px;
}

.blog-article {
    background-color: rgba(0, 148, 69, 0.084);
}

.blog-article-content {
    background-color: #fff;
    padding: 50px 30px;
    margin: -100px 0 100px 0;
    z-index: 2;
    position: relative;
}

.blog-article-content h1 {
    text-align: center;
    padding: 0 30px;
}

.blog-article-content .meta {
    text-align: center;
    margin: 15px 0 30px 0;
    font-weight: bold;
}

.blog-article-content .meta div {
    display: inline-block;
    padding: 5px 20px;
    position: relative;
}

.blog-article-content .meta div:after {
    content: "";
    height: 100%;
    width: 2px;
    display: block;
    background-color: #009444;
    position: absolute;
    right: -2px;
    top: 0;
}

.blog-article-content .meta div:last-of-type:after {
    display: none;
}

.blog-similar .blog-item {
    background-color: rgba(0, 148, 69, 0.084);
}

.blog-similar .blog-item-content {
    padding: 0 25px 15px 25px;
    background-color: #fff;
    width: 90%;
    margin: 0 5% 0 5%;
    position: relative;
    top: -30px;
}

.blog-similar .blog-item-content h3 {
    position: relative;
    padding: 0 0 10px 0;
    margin: 0 0 15px 0;
}

.blog-similar .blog-item-content h3:after {
    content: "";
    width: 120px;
    height: 2px;
    background-color: #009444;
    position: absolute;
    left: 0;
    bottom: 0;
}

.blog-similar .blog-item-date {
    font-weight: bold;
    margin: 10px 0 13px;
}

.blog-similar .blog-item-desc p:last-of-type {
    margin: 0;
}

.blog-similar .blog-item-content .btn {
    min-width: 150px;
}

.footer-payments li {
    display: inline-block;
    margin-right: 25px;
}


.get-promo {
    background-color: #d00719;
    margin: 8px 0 0 0;
    padding: 15px;
    position: relative;
}

.get-promo:after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    top: -15px;
    left: -15px;
    z-index: 2;
    border-radius: 100%;
    background-color: #fff;
}

.get-promo:before {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -15px;
    left: -15px;
    z-index: 2;
    border-radius: 100%;
    background-color: #fff;
}

.get-promo-code {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    font-size: 22px;
    line-height: 22px;
}

.get-promo-val {
    text-align: center;
    position: relative;
}

.get-promo-val .val {
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    color: #fff;
}

.get-promo-val .lab {
    font-size: 16px;
    background-color: #000;
    margin: 5px 0 0 0;
    padding: 0 15px;
    color: #fff;
    font-weight: bold;
    display: inline-block;
}

.get-promo-code {
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    border: 1px dashed #fff;
    padding: 5px 10px;
    margin: 0 15px 0 0;
}

.get-promo .btn {
    font-size: 16px;
    line-height: 32px;
    padding: 0 10px;
    min-height: 34px;
    margin: 10px 0 0 0;
    border: 0;
}

.callbtn {
    position: relative;
    z-index: 3;
}

.table-hint {
    margin: 0 0 5px 0;
    font-weight: bold;
    text-align: center;
}

.get-promo-val {
    margin: 0 15px;
    border-right: 1px dashed #fff;
}

.timer .days,
.timer .hours,
.timer .minutes,
.timer .seconds {
    position: relative;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    width: 100%;
    color: #fff;
    text-align: center;
    padding: 0 10px;
}

.timer .days {
    padding-left: 0;
}

.timer .seconds {
    padding-right: 0;
}

.timer .seconds:after {
    display: none !important;
}

.timer .days:after,
.timer .hours:after,
.timer .minutes:after,
.timer .seconds:after {
    content: "";
    height: 15px;
    width: 1px;
    display: block;
    background-color: #fff;
    position: absolute;
    top: 11px;
    right: 0;
    opacity: .6;
}

.timer div span {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    display: block;
}

.empty-blog-list h2 {
    text-align: center;
    padding: 50px 0;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
}

.search button .btn {
    min-width: inherit;
    min-height: inherit;
    line-height: 1;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    padding: 6px 10px;
}