/* product */
.product .product-img {
    position: relative;
    margin: 0 0 20px 0;
}

.product .product-add-fav {
    padding: 10px 0;
    background-color: #fff;
    outline: none;
    border:0;
    transition: all .4s;
}

.product .product-add-fav img {
    width: 22px;
}

.product .product-add-fav:hover {
    color:#7F8282;
}

.add-fav {
    z-index: 3;
    opacity: 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.product-labels {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 3;
}

.product-labels .product-label {
    padding: 10px 15px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    background-color: #000;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product .assortment {
    padding-bottom: 100px;
}

.product h1 {
    color: #231f20;
    font-size: 34px;
    line-height: 40px;
    font-weight: bold;
}

.product .product-info {
    padding: 0 0 0 50px;
}

.product .product-info h1 {
    margin-bottom: 20px;
}

.product .product-src {
    margin: 0 0 10px 0;
}

.product .product-stock {
    margin: 0 0 10px 0;
    display: table;
    font-size: 16px;
    padding: 6px 10px;
    border-radius: 5px;
}

.product .product-stock.in-stock {
    color: #00B448;
    background: #E3F7EB;
}

.product .product-stock.not-in-stock {
    background: #F6EEC7;
    color: #e30613;
}

.product .product-num {
    font-weight: 400;
}

.product .product-stock.in-stock i, .product .product-stock.not-in-stock i {
    margin-right: 5px;
}

.product .product-rate {
    position: relative;
    display: table;
}

.product .product-rate .rate-tooltip {
    position: absolute;
    top: 40px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #343a40;
    z-index: 3;
    display: none;
    width: 320px;

    box-shadow: 0 7px 10px 0 rgba(162, 162, 162, 0.12),
    0 0 1px 0 rgba(144, 144, 144, 0.25);
}

.product .product-rate .rate-tooltip:after {
    content: "";
    position: absolute;
    top: -23px;
    left: 0;
    height: 23px;
    width: 100%;
}

.product .product-rate .rate-tooltip:before {
    content: "";
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    position: absolute;
    top: -11px;
    left: 93px;
    background-color: #fff;
    border-left: 1px solid #343a40;
    border-top: 1px solid #343a40;
}

.product .product-rate:hover .rate-tooltip {
    display: block;
}

.product .product-rate .rate-tooltip .btn {
    margin: 15px auto 0;
    display: table;
    text-decoration: none;
    color: #fff;
}

.product .product-rate .rate-tooltip .rate-list ul {
    padding: 0 30px;
}

.product .product-rate .rate-list ul li {
    margin-bottom: 10px;
}

.product .product-rate .rate-list ul li i {
    color: #303539;
    font-size: 16px;
}

.product .product-rate .rate-list ul li .val {
    display: inline-block;
}

.product .product-rate .rate-list ul li .rate-progress-bar {
    display: inline-block;
    background-color: #009444;
    height: 5px;
    position: absolute;
}

.product .product-rate .rate-list ul li .rate-progress {
    position: relative;
    background-color: #CCCCCC;
    width: 100%;
    height: 5px;
}

.product .product-rate > ul {
    display: inline-block;
}

.product .product-rate > ul li {
    display: inline-block;
}

.product .product-rate > ul li i {
    color: #303539;
}

.product .product-rate .rate {
    display: inline-block;
    margin: 0 0 0 10px;
}

.product .product-rate a {
    display: inline-block;
    text-decoration: underline;

    color: #868695;
    font-size: 16px;
    font-weight: 300;
}

.product .product-rate a:hover {
    text-decoration: none;
    color: #009444;
}

.product .product-desc {
    margin: 20px 0 40px;
}

.product .product-desc.short {
    overflow: hidden;
    margin-bottom: 20px;
}

.product .product-desc.short .lab {
    color: #000;
    font-weight: bold;
    padding: 0 0 10px 0;
    margin: 0 0 15px 0;
    border-bottom: 1px solid #8d8f92;
}

.product .product-desc ul li {

    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.product .product-variants {
    margin: 20px 0 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.product .product-variants .lab {
    text-transform: uppercase;
    color: #000;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    display: block;
    width: 100%;
    margin: 0 0 8px 0;
}

.product .product-variants .product-variant {
    border: 1px solid #e2e2e2;
    color: #40414E;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    cursor: pointer;
    padding: 12px 19px;
    margin: 0 5px 0 0;
}


.product .product-variants .product-variant.colored {
    padding:5px 5px;
    display:flex;
    align-items: center;
}
.product .product-variants .product-variant .variant-color {
    display: inline-block;
    width:30px;
    height:30px;
    margin-right: 5px;
}

.product .product-variants .product-variant:hover,
.product .product-variants .product-variant.selected {
    border-color: #231f20;
}

.product .product-variants.alerts-border {
    border: 1px #B8801E solid;
    animation: blink 1s;
    animation-iteration-count: 1;
    padding: 5px;
}

.product-details ul.btn-list {
    border-bottom: 1px solid #B2B2B2;
}

.product-details ul.btn-list li {
    display: inline-block;
    margin-right: 35px;
}

.product-details ul.btn-list li button {
    padding: 0;
    min-width: auto;
    min-height: auto;
    font-size: 16px;
    position: relative;
}

.product-details ul.btn-list li button:before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background: #37394A;
    transition: all .5s;
}

.product-details ul.btn-list li button.collapsed {
    color: #7F8282;
}

.product-details ul.btn-list li button.collapsed:before {
    width: 0;
    background: #009444;
}

.product-details ul.btn-list li button.collapsed:hover {
    color: #009444;
}

.product-details ul.btn-list li button.collapsed:hover:before {
    width: 100%;
}

.product-details .product-reviews {
    margin: 40px 0
}

.product-details .product-reviews .comment-block img {
    height: 60px;
    margin-bottom: 15px;

}

.product-details .product-reviews .comment-block .comment-title {
    color: #40414E;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 24px;
}

.product-details .product-reviews .comment-block span {
    font-weight: 300;
    font-size: 15px;
    color: #40414E;
    margin-bottom: 16px;
    display: block;
}

/*table style*/

table {
    width: 100%;
}

table tr th {
    background: #40414e;
    color: #fff;
    vertical-align: middle;
    padding: 12px 10px;
    border-right: 1px solid #ccc;
    font-size: 15px;
}

table tr td {
    vertical-align: middle;
    padding: 12px 10px;
    font-size: 15px;
}

table tr th:last-child {
    border: none;
}

tr:nth-child(even) {
    background-color: #F8f9f9;
}

@keyframes blink {
    50% {
        border-color: #FBFAF9;
    }
}

.product .product-prices {
    margin: 0 0 25px 0;
    font-weight: bold;
}

.product .product-prices .new-price {
    font-size: 36px;
    line-height: 38px;
    font-weight: bold;
    color: #e67372;
    display: inline-block;
    position: relative;
}

.product .product-prices .new-price:before {
    content: "с вкл. ДДС";
    position: absolute;
    bottom: 0;
    left: 0;

    font-size: 14px;
    color: #716F6F;
    margin-bottom: -28px;
    font-weight: 500;

}

.product .product-prices .old-price {
    display: inline-block;
    text-decoration: line-through;
    color: #000000;
    font-size: 24px;
    margin-right: 15px;
    padding-right: 20px;
    border-right: 1px solid #8d8f92;
}

.product .product-prices .discount-price {
    display: inline-block;
    color: #009444;
    font-size: 24px;
    line-height: 24px;
    margin-left: 15px;
    padding-left: 20px;
    border-left: 1px solid #8d8f92;
    position: relative;
    top: 7px;
}

.product .product-prices .discount-price span {
    display: block;
    font-size: 12px;
    line-height: 12px;
}

.product .product-delivery-hint {
    margin: 20px 0;
    font-size: 20px;
    line-height: 24px;

}

.product .product-delivery-hint h5 {
    font-size: 20px;
    line-height: 24px;
}

.product .product-features {
    padding: 70px 0;
}

.product .product-features.gray {
    background-color: #FBFAF9;
}

.product .product-features h4 {
    font-size: 18px;
    line-height: 24px;
    color: #303539;
    margin: 0 0 15px 0;
}

.product .temperature img {
    margin: 0 0 8px 0;
}

.product .temperature span {
    display: block;
}

.product .region-info span {
    display: block;
    color: #c1c1c1;
}

.product .region-info span a {
    display: block;
    color: #c1c1c1;
}

.product .region-info .flag {
    display: block;
    margin: 5px 0 5px 0;
}

.product .product-sort {
    padding: 0 0 6px 0;
    margin-bottom: 50px;
    border-bottom: 1px solid #e3e2e2;
}

.product .product-aromas ul li {
    display: inline-block;
    text-align: center;
    line-height: 16px;
}

.product .product-aromas ul li img {
    display: block;
    margin: 0 auto 10px;
}

.product .suitable-foods ul li {
    display: inline-block;
    text-align: center;
    line-height: 18px;
}

.product .suitable-foods ul li img {
    display: block;
    margin: 0 auto 10px;
}

.product .product-profile ul li {
    padding: 12px 0 12px 0;
    border-bottom: 1px solid #e3e2e2;
}

.product .product-profile ul li .ingredient {
    display: inline-block;
    width: 95px;
}

.product .product-profile ul li .dot.empty {
    background-color: #DBD8DB;
}

.product .product-profile ul li .dot {
    background-color: #E81E25;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin: 0 4px;
    display: inline-block;
}

/*product list*/
.products-list .product-item {
    text-align: center;
    position: relative;
    border: 1px solid #fff;
}

.products-list .product-item:hover {
    border-color: #231f20;
}

.products-list .product-item .product-prices {
    margin: 0 0 12px;
}

.products-list .product-item .product-name {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    min-height: 75px;
    margin: 0 0 12px 0;
    position: relative;
    color: #40414E;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.products-list .product-item .product-prices .old-price {
    color: #716F6F;
    text-decoration: line-through;
    margin-right: 4px;
    font-size: 19px;
    line-height: 14px;
    display: inline-block;
}

.products-list .product-item .product-prices .price {
    display: inline-block;
    font-size: 26px;
    line-height: 30px;
}

.products-list .product-item .product-prices .price {
    font-weight: bold;
    color: #000;
}

.products-list .product-item {
    overflow: hidden;
    position: relative;
    padding: 0 20px;
    margin: 0 0 30px 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-img {
    overflow: hidden;
    position: relative;
    margin: 0 0 20px 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-info {
    background-color: #fff;
    padding: 10px 0 0 0;
    z-index: 3;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-info .stars li {
    display: inline-block;
}

.products-list .product-item:hover .product-info {

}

.products-list .product-item .product-action {
    opacity: 0;
    position: absolute;
    left: 10%;
    top: 50%;
    width: 80%;

    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.products-list .product-item:hover .product-action {
    opacity: 1;
}

.products-list .product-item .product-action .btn {
    width: 100%;
    display: block;
    margin: 0 0 10px 0;
    padding: 0 10px;
    font-size: 16px;
    letter-spacing: 0;
    min-width: 100%;
}

.products-list.product-recently .signature img {
    height: 20px;
}

.products-list .product-item:hover .add-fav {
    opacity: 1;
}

.products-list .product-item .small-icns {
    position: absolute;
    top: 10px;
    z-index:9;
    right: 10px;
}

.products-list .product-item .small-icns a img {
    width: 20px;
}

.products-list .product-item .small-icns a {
    margin: 0 10px;
    opacity: 0;
}

.products-list .product-item:hover .small-icns a {
    opacity: 1;
}

.product-labels .product-label.label-discount {
    background-color: #F0544F;
}

.product-labels .product-label.label-discount.discount-fixed {

    width: auto;
    max-width: 100px;
    padding: 2px 5px;
    border-radius: 4px;
    height: auto;
    line-height: inherit;
}

.product-labels .product-label.label-score {
    border: 2px solid #ddd;
    background-color: #fff;
    color: #333;
    font-size: 13px;
    line-height: 36px;
}

.product-labels li {
    margin-bottom: 5px;
}

.products-list .product-item .product-info .stars {
    margin-bottom: 5px;
}

.categories .products-list .product-item .product-name,
.favorites .products-list .product-item .product-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 75px;
}

.table-hint img {
    max-height: 30px;
    margin: 0 auto;
    display: block;
}

.product-details {
    margin: 50px 0 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .products-list .product-item .product-action .btn {
        font-size: 19px;
    }

    .products-list .product-item .product-prices .old-price {
        display: block;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .categories .products-list .product-item,
    .favorites .products-list .product-item {
        padding: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .categories .products-list .product-item .product-name,
    .favorites .products-list .product-item .product-name {
        min-height: 44px;
        font-size: 18px;
        line-height: 22px;
    }

    .categories .products-list .product-item .product-prices .price,
    .favorites .products-list .product-item .product-prices .price {
        font-size: 22px;
        line-height: 26px;
    }

}

@media only screen and (min-width: 0px) and (max-width: 1025px) {
    .products-list .product-item .small-icns a,
    .products-list .product-item .small-icns .add-fav {
        opacity: 1;
    }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {

    .product .product-features {
        padding: 50px 0;
    }

    .product .product-sort {
        margin-bottom: 25px;
    }

    .products-list .product-item {
        margin-bottom: 50px;
    }

    .products-list .product-item .product-action {
        position: inherit;
        left: inherit;
        top: inherit;
        width: 100%;
        margin: 30px 0 0 0;
        opacity: 1;
    }

    .products-list .product-item:hover .product-info {
        margin: 0 0 0 0;
        transform: translate(0, 0px);
    }

    .products-list.product-recently .product-item .product-prices .price,
    .products-list.product-recently .product-item .product-prices .old-price {
        font-size: 22px;
    }

    .products-list.product-recently .product-item .product-name {
        font-size: 22px;
        line-height: 24px;
    }

    .products-list .product-item:hover .product-img:after {
        margin-left: -70px;
    }

    .products-list .product-item .product-desc {
        color: #6A6665;
        min-height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .products-list .product-item .product-action .btn-secondary-outline {
        display: none;
    }

    .products-list .product-item .product-action .btn-primary {
        margin: 0;
    }

    .product h1 {
        font-size: 32px;
        line-height: 34px;
    }

    .products-list .product-item:hover {
        border-color: transparent;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {

    .products-list .product-item:hover {
        border-color: transparent;
    }

    .products-list .product-item .product-action {
        position: inherit;
        left: inherit;
        top: inherit;
        width: 100%;
        margin: 30px 0 0 0;
        opacity: 1;
    }

    .products-list .product-item:hover .product-info {
        margin: 0 0 0 0;
        transform: translate(0, 0);
    }

    .products-list.product-recently .product-item .product-prices .price,
    .products-list.product-recently .product-item .product-prices .old-price {
        font-size: 16px;
    }

    .products-list.product-recently .product-item .product-name {
        font-size: 20px;
        line-height: 22px;
        min-height: auto;
        margin: 0 0 10px 0;
    }

    .products-list.product-recently .product-item .product-prices .price,
    .products-list.product-recently .product-item .product-prices .old-price {
        font-size: 22px;
        line-height: 21px;
    }

    .products-list .product-item:hover .product-img:after {
        margin-left: -70px;
    }

    .products-list .product-item .product-action .btn {
        padding: 10px 5px;
        height: inherit;
        min-height: inherit;
        letter-spacing: 0;
        font-size: 12px;
        line-height: 16px;
        width: auto;
        min-width: auto;
    }

    .product .product-img {
        margin: 0 0 0 0;
    }

    .product .product-info h1 {
        margin-bottom: 16px;
    }

    .product .product-stock {
        font-size: 14px;
        margin: 0;
    }

    .product .assortment {
        padding-top: 50px;
        padding-bottom: 0;
    }

    .product .assortment .heading {
        margin-bottom: 30px;
    }

    .products .hero-area {
        padding: 30px 0;
    }

    .products .hero-area h1 {
        font-size: 34px;
        line-height: 40px;
    }

    .products-list .product-item .product-desc {
        font-size: 12px;
        height: 63px;
        overflow: hidden;
        position: relative;
    }

    .products-list .product-item .product-name {
        font-size: 21px;
        line-height: 21px;
        margin: 0 0 8px 0;
        min-height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .products-list .product-item .product-prices .price {
        font-size: 24px;
        line-height: 27px;
    }

    .products-list .product-item .product-prices .old-price {
        display: block;
    }

    .product .product-prices {
        margin: 10px 0 25px 0;
    }

    .products-list .product-item .product-action .btn-secondary-outline {
        display: none;
    }

    .products-list .product-item .product-action .btn-primary {
        margin: 0;
    }

    .categories .products-list .product-item,
    .favorites .products-list .product-item {
        padding: 0;
        margin-bottom: 20px;
    }

    .categories .products-list .product-item .product-name,
    .favorites .products-list .product-item .product-name {
        font-size: 16px;
        line-height: 20px;
        -webkit-line-clamp: 4;
        min-height: 80px;
    }

    .product .product-info {
        padding: 16px 0;
    }

    .product h1 {
        font-size: 26px;
        line-height: 30px;
    }

    .product .product-desc p {
        font-size: 15px;
    }

    .product .product-desc p strong {
        font-size: 17px;
    }

    .product .product-desc ul li {
        margin-left: 15px;
        font-size: 15px;
    }

    .product .product-prices .new-price {
        font-size: 30px;
        line-height: 32px;
    }

    .product .product-prices .old-price {
        font-size: 22px;
    }

    .product .btn.buy {
        width: 100%;
    }

    .products-list .product-item .small-icns {
        justify-content: flex-end;
    }

    .product .product-variants {
        margin: 16px 0 16px 0;
    }

    .product .product-variants .lab {
        margin: 0 15px 8px 0;
    }

    .product .product-variants .product-variant {
        font-size: 13px;
        line-height: 18px;
        margin: 0 6px 4px 0;
    }

    .product-details ul.btn-list li {
        margin-right: 22px;
    }

    .product-details ul.btn-list li button {
        font-size: 16px;
        line-height: 20px;
    }

    .product-details .product-reviews .product-title {
        font-size: 14px;
    }

    .product-details .product-reviews .btn {
        width: 100%;
    }

    .product-details .product-reviews .comment-block .comment-title {
        font-size: 16px;
        line-height: 22px;
    }

    .product-details .product-reviews .comment-block img {
        height: 50px;
    }

    .product .product-desc,
    .product-details .product-reviews {
        margin-bottom: 25px;
    }

    .product .product-rate .rate-tooltip {
        width: 290px;
        padding: 20px 10px;
    }

    .product .product-prices .new-price:before {
        margin-bottom: -18px;
        font-size: 12px;
        line-height: 18px;
    }
}

@media screen and (orientation: landscape)
and (min-device-width: 768px)
and (max-device-width: 1024px) {
    .products-list .product-item {
        margin-bottom: 0;
    }

    .products-list .product-item .product-action {
        opacity: 1;
    }

    .products-list .product-item:hover .product-info {
        margin: 0 0 0 0;
        transform: translate(0, 0);
    }
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
    .product-details ul.btn-list li {
        display: block;
        margin: 0 0 5px 0;
        text-align: left;
    }

    .product-details ul.btn-list li button {
        line-height: 22px;
        width: 100%;
        text-decoration: underline;
    }

    .product-details ul.btn-list li button.collapsed {
        text-decoration: none;
    }

    .product-details ul.btn-list li button:before {
        display: none;
    }
}