/*Profile*/
.profile {
    background-color: #F8F9F9;
    position: relative;
}

.profile .heading {
    text-align: left;
    margin: 0 0 40px 0;
}

.profile:after {
    content: "";
    min-height: 100%;
    width: 30%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
}

.profile .profile-content {
    background-color: #fff;
    padding: 60px 55px 100px 55px;
    position: relative;
    height: 100%;

    z-index: 1;
}

.profile .profile-content .order-row .order-num {
    margin-bottom: 20px;
}

.profile .profile-content .order-row .order-num span {
    padding-right: 15px;
}

.profile .profile-content .order-row .order-num span.btn-text {
    max-width: 140px;
    display: block;
    padding: 0 7px 0 0;

    font-size: 17px;
}

.profile .profile-content .order-row .order-num a.replay,
.profile .profile-content .order-row .order-num a.cancel,
.profile .profile-content .order-row .order-num a.pay-again {
    position: relative;
    font-size: 11px;
}

.profile .profile-content .order-row .order-num a.replay:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../../images/icon_1.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    transform: translate(50%, -50%);
    margin-left: 3px;
}

.profile .profile-content .order-row .order-num a.cancel:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../../images/icon_2.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    transform: translate(50%, -50%);
    margin-left: 3px;
}

.profile .profile-content .order-row .order-num a.cancel:hover:before {
    background: url("../../images/icon_2-white.svg") center no-repeat;
}

.profile .profile-content .order-row .order-num a.pay-again span {
    position: relative;
}

.profile .profile-content .order-row .order-num a.pay-again span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../../images/icon_3.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    transform: translate(-50%, -64%);
    margin-left: -15px;
}

.profile .profile-content .order-row .order-num a.pay-again:hover span:before {
    background: url("../../images/icon_3-greenn.svg") center no-repeat;
}

.profile .form-group {
    margin: 0 0 24px 0;
}

.profile-orders .order-row {
    padding: 20px;
    border: 1px solid #ECECEC;
    margin-bottom: 30px;
}

.profile-orders .order-row:last-of-type {
    margin-bottom: 0;
}

.profile-content .order-row {
    padding: 20px 20px 25px 20px;
    border: 1px solid #CCCCCC;
    margin-bottom: 20px;
}


.profile-content .order-row a.btn.pay-again {
    max-width: 180px;
}

.profile-content .order-row:last-of-type {
    margin-bottom: 0;
}

.profile-content .order-num {
    font-size: 28px;
    line-height: 30px;

    font-weight: bold;
    text-transform: uppercase;
    color: #40414E;
}

.profile .order-details.silver-bgr {
    padding: 20px 20px 25px 20px;
    border: 1px solid #CCCCCC;
    margin-bottom: 20px;
    position: relative;
}

.profile .order-details.silver-bgr .delete-address {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 8px;
    margin-right: 20px;
}

.profile .order-details.silver-bgr .delete-address:hover {
    opacity: 0.7;
}

.btn-edit {
    color: #868695;
    font-weight: 300;
    text-decoration: underline;
    font-size: 15px;
    margin: 0 0 20px 0;
}

.btn-edit:hover {
    text-decoration: none;
}

.btn-edit img {
    width: 16px;
    margin: -1px 2px 0 0;
}

.profile .order-details h3 {
    margin: 0 0 15px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.profile .order-details .lab {
    color: #7F8282;
    font-size: 15px;
}

.profile .order-details .val {
    font-weight: 500;
    font-size: 15px;
    color: #37394A;
}

.profile .val.success,
.profile .val.pending {
    background: #D9F5DD;
    display: table;
    border-radius: 6px;
    padding: 2px 12px;
    margin-top: -1px;
    color: #48A56A;
    font-weight: 500;
}

.profile .order-details .val.pending {
    background: #F6EEC7;
    color: #B8801E;
}

.profile .order-details .active {
    font-weight: bold;
    font-size: 14px;
    color: white;
    background-color: forestgreen;
    text-align: center;
    max-width: 130px;
    border-radius: 4px;
}

.profile .order-details .inactive {
    font-weight: bold;
    font-size: 14px;
    color: white;
    background-color: #dc3545;
    text-align: center;
    max-width: 130px;
    border-radius: 4px;
}

.profile-order .order-products {
    padding: 0 20px;
    border: 1px solid #CCCCCC;
    margin: 0 0 30px 0;
}

.profile-order .order-products .product-row:last-of-type {
    border-bottom: 0;
}

.profile .order-products {
    margin-bottom: 20px;
}

.profile .order-products .product-row .product-img {
    max-width: 80px;
}

.profile .order-products .product-row .lab {
    margin: 0 0 7px 0;
    color: #909090;
}

.profile .order-row .view-order {
    display: table;
    color: #868695;
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
    text-decoration: underline;
    text-transform: initial;

}

.profile .order-row .view-order:hover {
    text-decoration: none;
    color: #009444;
}

.profile #flash-messages-container {
    background: #fff;
    margin-left: 15px;
}

.profile .order-row .view-order i {
    margin-left: 5px;
}

.profile .order-products.total.with-border {
    padding-bottom: 0;
}

.profile .order-products .product-row:last-child {
    border-bottom: 0;
}

.profile .product-price {
    text-align: right;
}

.profile-address-list .btn {
    margin: 30px 0 0 0;
    display: table;
}

.profile-address-list .edit-address {
    display: block;
}

.profile .add-address-btn {
    text-align: center;
    display: block;
}

.profile .add-address-btn:hover {
    background: #fff;
}

.profile.profile-cards .btn-sm {
    padding: .25rem .5rem;
    font-size: 12px;
    line-height: 1.5;
    height: auto;
    letter-spacing: 0;
    text-transform: none;
}

.user-menu {
    margin: 60px 0 60px 0;

}

.user-menu li {
    display: block;
    position: relative;
}

.user-menu li:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 85%;
    margin-left: 10px;
    height: 1px;
    background: #CCCCCC;
}

.user-menu li.active a {
    background: rgb(248, 249, 249);
    background: linear-gradient(90deg, rgba(248, 249, 249, 1) 9%, rgba(255, 255, 255, 1) 50%);
    color: #303539;
}

.user-menu li a:hover {
    color: #009444;
}

.user-menu li.active a:hover {
    color: #303539;
}

.user-menu li a {
    display: block;
    padding: 12px 10px 15px 10px;
    position: relative;
    font-weight: 500;
    color: #76767D;
}

.user-menu li a img {
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
    min-width: 25px;
    margin-top: -4px;

    -webkit-transition: all .4s;
    transition: all .4s;
}

.user-menu li a img.icn {
    display: inline-block;
}

.user-menu li a:hover img.icn {
    display: none;
}

.user-menu li a img.icn-hover {
    display: none;
}

.user-menu li a:hover img.icn-hover {
    display: inline-block;
}

.user-menu li a img.icn-active {
    display: none;
}

.user-menu li.active a img.icn-active {
    display: inline-block;
}

.user-menu li.active a img.icn,
.user-menu li.active a img.icn-hover {
    display: none;
}

.profile .btn {

}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
    .profile .profile-content {
        padding: 60px 15px 65px 30px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1200px) {
    .user-menu li a {
        font-size: 16px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {

    .profile .profile-content {
        padding: 60px 5px 65px 20px;
    }

    .profile .order-details .val {
        margin: 0 0 16px 0;
        line-height: 18px;
    }

    .profile .product-price {
        text-align: left;
    }

    .profile .order-details {
        margin: 0 0 20px 0;
    }

    .profile .order-products .product-row .product-name {
        margin: 0;
    }

    .profile-content .order-row a.btn {
        max-width: 180px;
    }

    .profile .profile-content .order-row .order-num span {
        display: block;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .profile:after {
        display: none;
    }

    .profile .profile-content {
        margin: 15px 0 40px 0;
        padding: 20px 15px 30px 15px;
        height: auto;
        border: 1px solid #ccc;
        font-size: 15px;
    }

    .profile .heading {
        margin: 0 0 5px 0;
    }

    .profile .form-group {
        margin: 0 0 20px 0;
    }

    .profile .order-details .val {
        margin: 0 0 12px 0;
        line-height: 18px;
        font-size: 14px;
    }

    .profile .order-details .val.product-price {
        font-size: 15px;
        font-weight: 700;
    }

    .total.with-border h3 {
        padding: 0 15px;
    }

    .profile .order-details h3 {
        font-size: 26px;
        line-height: 28px;
    }

    .profile .val.success, .profile .val.pending {
        padding: 2px 10px;
    }

    .profile .product-price {
        text-align: left;
        margin: 5px 0 0 0;
        font-weight: bold;
        font-size: 14px;
        color: #000;
    }

    .profile .order-details.silver-bgr {
        padding: 20px 15px 20px 15px;
    }

    .profile-content .order-num {
        padding: 0;
        font-size: 25px;
    }

    .profile .order-details .lab {
        font-size: 14px;
    }

    .profile .profile-content .order-row .order-num span {
        display: block;
    }

    .profile .profile-content .order-row .order-num a.pay-again {
        min-width: 100%;
        justify-content: center;
    }

    .profile .profile-content .order-row .order-num .pay-now {
        width: 100%;
    }

    .profile .profile-content .order-row .order-num span.btn-text {
        max-width: 100% !important;
    }

    .profile .profile-content .order-row .buttons-block {
        width: 100%;
        justify-content: space-between;
        margin: 0;
    }

    .profile .profile-content .order-row .order-num div a.btn {
        max-width: 100%;
        width: 100%;
    }

    .profile .profile-content .order-row .cancel-button,
    .profile .profile-content .order-row .btn-order {
        width: 48%;
    }

    .profile .profile-content .order-row .order-num a.replay,
    .profile .profile-content .order-row .order-num a.cancel {
        background: white;
        border: none;
        color: #303539;
    }

    .profile-address-list .btn {
        margin: 20px 0 0 0;
    }

    .user-menu li:before {
        width: 95%;
        margin-left: 0;
    }

    .user-menu li a img {
        margin-right: 2px;
    }

    .user-menu li a {
        padding: 12px 10px 15px 0;
    }

    .btn-edit {
        font-size: 14px;
    }

    .btn-edit img {
        width: 14px;
    }

    .profile .add-address-btn {
        padding: 12px;
        font-size: 24px;
    }

    .profile .order-details.silver-bgr .delete-address {
        margin-top: 10px;
        margin-right: 15px;
    }
}

@media (max-width: 575px) {
    .profile .profile-content .order-row .order-num span {
        display: block;
        width: 100%;
    }

    .profile .profile-content .order-row .order-num a span {
        display: block;
        width: 100%;
        max-width: 149px !important;
        padding: 6px 0 6px 25px !important;
    }

    .profile .profile-content .order-row .order-num div a.btn {
        font-size: 9px;
        padding: 0 6px;
        line-height: 15px;
        min-height: 43px;
    }

    .profile-content .order-row {
        padding: 11px 10px;
    }

    .profile .order-row .view-order {
        margin: 11px 0 0 auto;
    }

    .profile .profile-content .order-row .order-num a.replay:before {
        margin-left: 0;
        margin-top: -2px;
    }

    .profile .profile-content .order-row .order-num a.cancel:before {
        margin-left: 0;
    }

    .profile-content .order-num .cancel-button {
        width: 104px !important;
    }

    .profile .profile-content .order-row .order-num div a.pay-again {
        text-align: center;
        padding-left: 0 !important;
    }

    .profile .profile-content .order-row .order-num a.pay-again span:before {
        left: auto;
    }
}