/*form components*/
.radio,
.checkbox {
    padding: 0 0 0 26px;
    position: relative;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    position: absolute;
}

.form-group {
    margin-bottom: 20px;
}

.checkbox label {
    color: #40414E;
    font-weight: 400;
}

.checkbox label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    border: 1px solid #8d8f92;
    background-color: #fff;
    margin-top: 2px;
}

.checkbox label::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 2px;
    top: 0;
    font-size: 12px;
    color: #555555;
    margin-top: 4px;
}

.checkbox input[type="checkbox"]:checked + label::after {
    font-family: "FontAwesome";
    content: "\f00c";
}

.radio label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;

    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.radio label::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    border-radius: 50%;
    background-color: #555555;

    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);

    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.form-control:disabled, .form-control[readonly] {
    background-color: #F1F1F1;
}

input[type=radio] {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    margin-top: 1px;
    outline: none;
    box-shadow: none;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

input[type=radio]:checked {
    border: 6px solid #CB333B;
    background: #fff;
    outline: none;
    box-shadow: none;
}

input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    display: inline-block;
}

input[type="radio"]:checked + label:before,
.delivery-method label:hover:before {
    border: 6px solid #009444 !important;
}

input[type="radio"]:not(:checked) + label:before {
    border: 2px solid #DCDADA;
}

input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    width: 19px;
    height: 19px;
    border-radius: 100%;
    background: #fff;
    top: 50%;
    margin-top: -9px;
    margin-left: 14px;
}


input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
    content: "";
    width: 6px;
    height: 6px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 100%;
    transition: all 0.2s ease;
    margin-top: -3px;
    margin-left: 6px;
}

input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

input[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.switch label {
    cursor: pointer;
}

.switch label input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch label input[type="checkbox"]:not(:checked),
.switch label input[type="checkbox"]:checked {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}

.switch label .lever {
    position: relative;
    display: inline-block;
    margin: 0 1rem;
    margin-right: .625rem;
    vertical-align: middle;
    content: "";
    background-color: #E2E1E1;
    border-radius: .9375rem;
    width: 2.5rem;
    height: .9375rem;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
}

.switch label .lever:after {
    position: absolute;
    top: -.1875rem;
    left: -.3125rem;
    display: inline-block;
    content: "";
    background-color: #8C8B8B;
    border-radius: 1.3125rem;
    width: 1.3125rem;
    height: 1.3125rem;
    -webkit-transition: left .3s ease, background .3s ease, -webkit-box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, -webkit-box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, box-shadow 1s ease, -webkit-box-shadow 1s ease;
}

.switch label input[type="checkbox"]:checked + .lever:after {
    left: 1.5rem;
    background-color: #009444;
}

.select2-container {

    font-size: 14px;
    width: 100% !important;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow {
    width: 30px;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__arrow b {
    border-width: 7px 7px 0;
    opacity: 0.4;
    margin-top: -4px;
}

.sort-result select {
    font-weight: 500;
    color: #6E7878;
    min-height: 42px;
    padding-left: 36px;
    line-height: 20px;
}

.sort-result .form-group {
    position: relative;
}

.sort-result .form-group img {
    position: absolute;
    left: 0;
    top: 50%;
    height: 15px;
    margin-top: -7.5px;
    margin-left: 13px;
}

textarea.form-control {
    padding: 10px 15px;
}

.form-control {
    font-size: 16px;
    color: #000000;
    border: 1px solid #8d8f92;
    padding: 10px 15px 10px 45px;
    background-color: #fff;
    height: 50px;
    font-weight: normal;
    border-radius: 0;
}

.form-control::placeholder {
    color: #000000;
}

.form-control:focus {
    color: #000;
    border-color: #009444;
    background-color: rgba(26, 87, 255, 0.033);
    box-shadow: none;
}

label {

    font-weight: 500;
    color: #333333;
    font-size: 15px;
    margin-bottom: 3px;
}

select {
    background: #fff url("../../images/icons/polygon.svg") right 0.75rem center/8px 10px no-repeat;
    background-position-x: 96%;
    background-size: 12px;
    background-position-y: 22px;
    appearance: none;
    -webkit-appearance: none;
}

.form-icon {
    position: relative;
}

.form-icon img {
    position: absolute;
    top: 50%;
    left: 15px;
    width: 20px;
    transform: translate(0, -50%);
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .sort-result select {
        padding-right: 28px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sort-result select {
        font-size: 14px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .sort-result .form-group img {
        height: 14px;
    }

    .sort-result select {
        padding-left: 34px;
    }

    .sort-result .custom-select {
        padding-left: 32px;
    }

    .form-control {
        min-height: 42px;
        font-size: 14px;
    }

    .form-group {
        margin-bottom: 16px;
    }

    input[type="radio"]:checked + label:before, input[type="radio"]:not(:checked) + label:before {
        width: 16px;
        height: 16px;
    }

    input[type="radio"]:checked + label:after, input[type="radio"]:not(:checked) + label:after {
        width: 6px;
        height: 6px;
        margin-top: -4px;
        margin-left: 19px;
    }
}