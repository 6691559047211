
.range-slider {
    position: relative;
    height: 55px;
}

.irs--round .irs-from, .irs--round .irs-to,
.irs--round .irs-single {
    background-color: #009444;
    border-radius: 0;
    display: none;
}

.irs--round .irs-from:before, .irs--round .irs-to:before,
.irs--round .irs-single:before {
    border-top-color: #009444;
}

.irs--round .irs-min, .irs--round .irs-max {
    border-radius: 0;
    color: #fff;
    background-color: #04B8C2;
}

.irs--round .irs-handle.state_hover, .irs--round .irs-handle:hover {
    background: #04B8C2;
    border-color: #04B8C2;
}

.irs--round .irs-bar {
    background-color: #009444;
    height: 3px;
}

.irs--round .irs-handle {
    border: 4px solid #009444;
    top: 28px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 0;
    box-shadow: none;
}

.irs--round .irs-line {
    background: #CCCFCF;
    height: 3px;
}

.slider-module .owl-carousel .carousel-block {
    position: absolute;
    right: 15%;
    bottom: 25%;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 18px;
    color: #fff;
    text-align: center;
}

.slider-module .owl-carousel .carousel-block h1 {
    color: #E5BA5F;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 15px;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 11px;
}

.slider-module .owl-carousel .carousel-block h2 {
    color: white;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 38px;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    line-height: 45px;
    margin-bottom: 23px;
}

.slider-module .owl-carousel .carousel-block p {
    font-family: Montserrat, sans-serif;
    color: white;
    margin: 0 auto 22px auto;
    width: 300px;
    font-size: 15px;
    line-height: 23px;
}

.slider-module .owl-carousel .owl-dots {
    position: absolute;
    left: 50%;
    margin-left: -60px;
    bottom: 0;
    margin-bottom: 27px;
}

.slider-module .owl-theme .owl-dots .owl-dot span {
    width: 14px;
    height: 14px;
    margin: 5px 5px;
    background: #AD9587;
}

.slider-module .owl-theme .owl-dots .owl-dot.active span {
    border: 2px solid white;
    background: transparent;
}

.slider-module .owl-carousel .owl-dots button {
    outline: none;
}

.slider-module .owl-carousel .owl-nav {
    margin: 0;
}

.slider-module .owl-carousel .owl-nav button {
    position: absolute;
    top: 50%;
    margin-top: -32px;
    width: 47px;
    height: 47px;
    border-radius: 0;
}

.slider-module .owl-carousel .owl-nav button span {
    font-size: 0;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    display: block;
    width: 16px;
    height: 16px;
}

.slider-module .owl-carousel .owl-nav button.owl-next {
    right: 0;
    margin-right: 20px;
    background: #493C34;
}

.slider-module .owl-carousel .owl-nav button.owl-next:hover {
    background: #493C34;
    opacity: 0.7;
}

.slider-module .owl-carousel .owl-nav button.owl-next span {
    left: 10px;
    transform: rotate(225deg);
    position: relative;
}

.slider-module .owl-carousel .owl-nav button.owl-prev {
    left: 0;
    background: #2A211E;
    margin-left: 20px;
}

.slider-module .owl-carousel .owl-nav button.owl-prev:hover {
    opacity: 0.7;
    background: #2A211E;
}

.slider-module .owl-carousel .owl-nav button.owl-prev span {
    transform: rotate(45deg);
    position: relative;
    left: 18px;
}

.slider-module .owl-carousel .owl-nav button span {
    color: white;
    font-size: 0;
}

/*owl*/
.owl-carousel .owl-nav {
    width: 100%;
}

.owl-carousel .owl-nav button .fa {
    font-size: 60px;
    line-height: 60px;
    height: 63px;
    display: block;
}

.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -32px;
    margin-left:0;
    padding: 0 12px 5px 12px !important;
}

.owl-carousel .owl-nav button.owl-prev span {
    background: url('../../images/icons/arrow-left.svg') no-repeat;
}

.owl-carousel .owl-nav button.owl-prev:hover span {
    background: url('../../images/icons/black-left-arrow.svg') no-repeat;
}

.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 0;
    margin-top: -32px;
    padding: 0 6px 5px 14px !important;

}

.owl-carousel .owl-nav {
    display: block !important;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
    background: transparent;
    height: 80px;
    opacity: .9;
    width: auto;
    top: 50%;
    bottom: auto;
    margin-top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
    outline: 0;
    background: #F0EFEA;
}

.owl-carousel .owl-nav button span {
    margin-top: 12px;
    background-size: 100%;
    width: 12px;
    height: 24px;
    display: block;
    font-size: 0;
}

.owl-carousel .owl-nav button.owl-next:hover span {
    background: url('../../images/icons/black-right-arrow.svg') no-repeat;
}

.owl-carousel .owl-nav button.owl-next span {
    background: url('../../images/icons/arrow-right.svg') no-repeat;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: none;
    color: #869791;
}

.owl-theme .owl-nav [class*=owl-] {
    outline: none;
}

.carousel-indicators li {
    background-color: #000;
    opacity: 0.13;
    height: 2px;
    width: 45px;
    margin: 0 4px;
}

.carousel-indicators li.active {
    opacity: 1;
    background-color: #fff;
    height: 6px;
}

.carousel-control-prev-icon {
    background: url('../../images/icons/arrow-left.svg') no-repeat;
}

.carousel-control-next-icon {
    background: url('../../images/icons/arrow-right.svg') no-repeat;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 20px;
    height: 30px;
    background-size: 10px;
    margin-top: 12px;
}

.carousel-control-next, .carousel-control-prev {
    background: #F0EFEA;
    height: 80px;
    padding: 0 6px 0 14px;
    opacity: .7;
    width: auto;
    top: 50%;
    bottom: auto;
    margin-top: -40px;
}

.carousel-item {
    background-position: 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 30.46%;
}

