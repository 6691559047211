@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-ExtraLight.eot');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans ExtraLight'), local('AlumniSans-ExtraLight'),
    url('../../fonts/AlumniSans-ExtraLight.woff2') format('woff2'),
    url('../../fonts/AlumniSans-ExtraLight.woff') format('woff'),
    url('../../fonts/AlumniSans-ExtraLight.ttf') format('truetype'),
    url('../../fonts/AlumniSans-ExtraLight.svg#AlumniSans-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-Light.eot');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans Light'), local('AlumniSans-Light'),
    url('../../fonts/AlumniSans-Light.woff2') format('woff2'),
    url('../../fonts/AlumniSans-Light.woff') format('woff'),
    url('../../fonts/AlumniSans-Light.ttf') format('truetype'),
    url('../../fonts/AlumniSans-Light.svg#AlumniSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-Regular.eot');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans Regular'), local('AlumniSans-Regular'),
    url('../../fonts/AlumniSans-Regular.woff2') format('woff2'),
    url('../../fonts/AlumniSans-Regular.woff') format('woff'),
    url('../../fonts/AlumniSans-Regular.ttf') format('truetype'),
    url('../../fonts/AlumniSans-Regular.svg#AlumniSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-MediumItalic.eot');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans Medium Italic'), local('AlumniSans-MediumItalic'),
    url('../../fonts/AlumniSans-MediumItalic.woff2') format('woff2'),
    url('../../fonts/AlumniSans-MediumItalic.woff') format('woff'),
    url('../../fonts/AlumniSans-MediumItalic.ttf') format('truetype'),
    url('../../fonts/AlumniSans-MediumItalic.svg#AlumniSans-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-Medium.eot');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans Medium'), local('AlumniSans-Medium'),
    url('../../fonts/AlumniSans-Medium.woff2') format('woff2'),
    url('../../fonts/AlumniSans-Medium.woff') format('woff'),
    url('../../fonts/AlumniSans-Medium.ttf') format('truetype'),
    url('../../fonts/AlumniSans-Medium.svg#AlumniSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-SemiBoldItalic.eot');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans SemiBold Italic'), local('AlumniSans-SemiBoldItalic'),
    url('../../fonts/AlumniSans-SemiBoldItalic.woff2') format('woff2'),
    url('../../fonts/AlumniSans-SemiBoldItalic.woff') format('woff'),
    url('../../fonts/AlumniSans-SemiBoldItalic.ttf') format('truetype'),
    url('../../fonts/AlumniSans-SemiBoldItalic.svg#AlumniSans-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-SemiBold.eot');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans SemiBold'), local('AlumniSans-SemiBold'),
    url('../../fonts/AlumniSans-SemiBold.woff2') format('woff2'),
    url('../../fonts/AlumniSans-SemiBold.woff') format('woff'),
    url('../../fonts/AlumniSans-SemiBold.ttf') format('truetype'),
    url('../../fonts/AlumniSans-SemiBold.svg#AlumniSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-Bold.eot');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans Bold'), local('AlumniSans-Bold'),
    url('../../fonts/AlumniSans-Bold.woff2') format('woff2'),
    url('../../fonts/AlumniSans-Bold.woff') format('woff'),
    url('../../fonts/AlumniSans-Bold.ttf') format('truetype'),
    url('../../fonts/AlumniSans-Bold.svg#AlumniSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-ExtraBold.eot');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans ExtraBold'), local('AlumniSans-ExtraBold'),
    url('../../fonts/AlumniSans-ExtraBold.woff2') format('woff2'),
    url('../../fonts/AlumniSans-ExtraBold.woff') format('woff'),
    url('../../fonts/AlumniSans-ExtraBold.ttf') format('truetype'),
    url('../../fonts/AlumniSans-ExtraBold.svg#AlumniSans-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('../../fonts/fontawesome-webfont.eot');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/fontawesome-webfont'), local('fontawesome-webfont'),
    url('../../fonts/fontawesome-webfont.woff2') format('woff2'),
    url('../../fonts/fontawesome-webfont.woff') format('woff'),
    url('../../fonts/fontawesome-webfont.ttf') format('truetype'),
    url('../../fonts/fontawesome-webfont.svg') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-Black.eot');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans Black'), local('AlumniSans-Black'),
    url('../../fonts/AlumniSans-Black.woff2') format('woff2'),
    url('../../fonts/AlumniSans-Black.woff') format('woff'),
    url('../../fonts/AlumniSans-Black.ttf') format('truetype'),
    url('../../fonts/AlumniSans-Black.svg#AlumniSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('../../fonts/AlumniSans-Italic.eot');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('../../fonts/Alumni Sans Italic'), local('AlumniSans-Italic'),
    url('../../fonts/AlumniSans-Italic.woff2') format('woff2'),
    url('../../fonts/AlumniSans-Italic.woff') format('woff'),
    url('../../fonts/AlumniSans-Italic.ttf') format('truetype'),
    url('../../fonts/AlumniSans-Italic.svg#AlumniSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Inter';
    src: url('../../fonts/Inter-Light.eot');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('../../fonts/Inter Light'), local('Inter-Light'),
    url('../../fonts/Inter-Light.woff2') format('woff2'),
    url('../../fonts/Inter-Light.woff') format('woff'),
    url('../../fonts/Inter-Light.ttf') format('truetype'),
    url('../../fonts/Inter-Light.svg#Inter-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Inter';
    src: url('../../fonts/Inter-Regular.eot');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('../../fonts/Inter Regular'), local('Inter-Regular'),
    url('../../fonts/Inter-Regular.woff2') format('woff2'),
    url('../../fonts/Inter-Regular.woff') format('woff'),
    url('../../fonts/Inter-Regular.ttf') format('truetype'),
    url('../../fonts/Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../fonts/Inter-Medium.eot');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('../../fonts/Inter Medium'), local('Inter-Medium'),
    url('../../fonts/Inter-Medium.woff2') format('woff2'),
    url('../../fonts/Inter-Medium.woff') format('woff'),
    url('../../fonts/Inter-Medium.ttf') format('truetype'),
    url('../../fonts/Inter-Medium.svg#Inter-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../fonts/Inter-SemiBold.eot');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('../../fonts/Inter SemiBold'), local('Inter-SemiBold'),
    url('../../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../../fonts/Inter-SemiBold.woff') format('woff'),
    url('../../fonts/Inter-SemiBold.ttf') format('truetype'),
    url('../../fonts/Inter-SemiBold.svg#Inter-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}



