/*cart*/
.cart-container {
    position: absolute;
    top: 43px;
    right: 0;
    width: 350px;
    z-index: 11;
    background-color: #fff;
    padding: 10px 15px 20px;
    text-align: left;
    display: none;
    border-top: 2px solid #d9d9d9;


    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.cart-container:before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    top: -26px;
}

.cart-widget:after {
    content: "";
    height: 2px;
    width: 350px;
    position: absolute;
    right: 0;
    bottom: -21px;
    background-color: #009444;
    display: none;
    z-index: 11;
}

.cart-widget:hover:after {
    display: block;
}

.cart-widget:hover .cart-container {
    display: block;
}

.cart-container .cart-result {
    border-bottom: 1px solid #ddd;
    max-height: 340px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
}

.cart-container .cart-result .product-image img {
    width: 100%;
}

.cart-container .cart-result .result-row {
    padding: 15px 0;
    border-bottom: 1px solid #d9d9d9;
}

.cart-container .cart-result .result-row:last-of-type {
    border: 0;
}

.cart-container .cart-result .product-title {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    position: relative;
    display: block;
    margin: 0 0 10px 0;
}

.cart-container .cart-result .product-remove {
    position: relative;
    top: -4px;
    font-size: 24px;
    line-height: 1;
    cursor: pointer;
    font-weight: normal;
    color: #82817C;
}

.cart-container .cart-result .product-mill {
    color: #cecece;
}

.cart-container .cart-total {
    font-size: 15px;
    padding: 14px 0 10px 0;
    color: #7F8282;
    font-weight: 500;
}

.cart-container .cart-result .product-amount {
    color: #716F6F;
    font-size: 12px;
    font-weight: 500;
}

.cart-container .cart-result .product-amount span {
    font-size: 14px;
    line-height: 18px;
    color: #40414E;
    font-weight: 600;
    vertical-align: text-top;
    margin-right: 4px;
}

.cart-container .cart-result .product-amount small {
    font-size: 14px;
    line-height: 18px;
    color: #6A6665;
}

.cart-container .empty-cart {
    padding: 20px;
    text-align: center;
    color: #cecece;
}

.cart-container .empty-cart img {
    margin: 0 auto 15px;
    display: block;
    height: 60px;
}

.cart-container .cart-total span {
    float: right;
    color: #37394A;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}

.cart-container .btn {
    width: 100%;
    display: block;
    margin: 5px 0 0 0;

}

.cart-empty {
    text-align: center;
}

.cart-empty img {
    height: 150px;
    margin: 0 0 10px 0;
}

.cart {
    padding: 45px 0 100px;
}

.cart h1 {
    margin: 0 0 8px 0;
    font-weight: bold;
}

.back-to-shop {
    font-size: 15px;
    color: #868695;
    text-decoration: underline;
}

.back-to-shop i {
    font-size: 17px;
    margin-right: 2px;
}

.back-to-shop + .cart-headings {
    margin-top: 20px;
    font-size: 15px;
}

.guarante-cart img {
    margin-right: 5px;
}

.cart-list {
    padding: 10px 0;
}

.promo-code {
    padding: 20px 20px 30px 20px;
    margin: 0 0 20px 0;
    border: 1px solid #cccccc;
}

.promo-code h3 {
    margin: 0 0 15px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.promo-code .btn {
    padding: 0 15px;
    min-width: 150px;
}

.cart-list .product-name {
    margin: 0 0 5px 0;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #40414E;

}

.cart-list .product-name:hover {
    color: #009444;
}

.cart-list .product-img {
    text-align: center;
    max-height: 140px;
}

.cart-list .product-img img {
    width: 100%;
}

.cart-list .product-old-price {
    color: #716F6F;
    font-size: 12px;
}

.cart-list .product-desc {
    font-size: 14px;
    line-height: 18px;
    color: #868695;
}

.cart-list .product-desc div {
    margin-bottom: 2px;
}

.cart-list .cart-product-price {
    font-size: 15px;
    color: #40414E;
    font-weight: 500;
}

.product-quantity {
    position: relative;
    border: 1px solid #CCCCCC;
    min-width: 80px;
}

.product-quantity input[type=number] {
    width: 100%;
    text-align: center;
    border: 0;
    height: 42px;
    font-size: 18px;
    line-height: 42px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

.product-quantity button {
    background-color: #fff;
    border: 0;
    color: #ABACB2;
    position: absolute;
    font-size: 25px;
    top: 0;
    height: 42px;
    width: 40px;
    outline: none;
}

.product-quantity .minus {
    left: 0;
    border-right: 1px solid #ccc;
}

.product-quantity .plus {
    right: 0;
    border-left: 1px solid #ccc;
}

.cart-list .product-quantity input[type=number] {
    height: 35px;
    font-size: 16px;
    line-height: 1.5;
}

.cart-list .product-quantity button {
    height: 35px;
    width: 29px;
}

.cart-list .total-price {
    font-size: 18px;
    color: #40414E;
    font-weight: 600;
}

.cart-list .remove-product {
    font-size: 20px;
    color: #7F8282;
    opacity: 1;
    margin-left: auto;
    margin-top: -1px;
}

.cart-list .remove-product:hover {
    opacity: 0.7;
}

.cart-delivery-hint {
    background-color: #EDDDCD;
    padding: 11px 15px;
    margin: 20px 0 20px 0;
    font-size: 15px;
}

.cart-headings {
    padding: 10px 25px;
}

.cart-list .cart-row {
    padding: 15px 25px;
    border-top: 1px solid rgba(141, 143, 146, 1);
}

.cart-list .cart-row .package-discounts .badge {
    font-size: 13px;
    margin: 5px 0;
}

.total {
    padding: 25px 20px 4px 20px;
    border: 2px solid rgba(0, 148, 68, 1);
    border-bottom: 0;
}

.total.with-border {
    border-bottom: 2px solid rgba(0, 148, 68, 1);
    padding: 25px 20px 10px 20px;
}

.total.with-border h3 {
    padding: 0;
}

.total.with-border .sum {
    padding: 16px 20px 0 20px;
}

.total h3 {
    margin: 0 0 15px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.total .product-row {
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
}

.total .product-row .product-img {
    margin-right: 15px;
}

.total .product-row .product-name {
    font-weight: 600;
    margin: 0 0 10px 0;
    color: #40414E;
}

.checkout .total .product-row .product-name {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.total .product-row .product-desc {
    color: #868695;
    font-size: 13px;
    line-height: 18px;
}

.total .product-row .product-old-price {
    text-decoration: line-through;
    color: #716F6F;
    font-size: 12px;
}

.total .product-row .product-price {
    color: #37394A;
    text-align: right;
    font-weight: 600;
}

.total .product-row .total-sum {
    color: #40414E;
    font-weight: 500;
}

.total .product-row .quantity {
    color: #ABAFAF;
    font-size: 14px;
    letter-spacing: 1px;
}

.total .total-hint {
    padding: 20px 25px 20px 60px;
    position: relative;
    border-top: 1px solid #e3e2e2;
    background-color: #FBFAF9;
    margin: 0 0 0 0;
}

.total .total-hint p {
    color: #6A6665;
}

.total .total-hint p:last-of-type {
    margin: 0;
}

.total .total-hint img {
    position: absolute;
    top: 25px;
    left: 20px;
}

.total .sum ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 16px;
    font-weight: 500;
    color: #7F8282;
    margin-bottom: 10px;
}

.total .sum ul li span {
    color: #37394A;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
}

.total .sum ul li:last-child {
    color: #40414E;
    font-weight: 700;
    font-size: 18px;
}

.total .sum ul li:last-child span {
    color: #37394A;
    font-weight: 700;
    font-size: 20px;
}

.total .sum ul li .hint,
.total .sum ul li:last-child span.hint {
    font-size: 14px;
    color: #716F6F;
    font-weight: 500;
    display: block;
    line-height: 13px;
}

.total .sum ul li span.total-price {
    position: relative;
}


.total-action {
    padding: 0 20px 15px 20px;
    border: 2px solid rgba(0, 148, 68, 1);
    border-top: 0;
    font-size: 14px;

    background-color: #fff;
}

.total-action .checkbox {
    margin: 0 0 8px 0;
}

.total-action .checkbox .form-group {
    margin-bottom: 9px;
}

.total-action .checkbox label::after {
    margin-top: 2px;
}

.total-action .sum {
    margin-bottom: 30px;
}

.total-action .btn {
    display: block;
    width: 100%;

    padding: 0 10px;
}

.guarante-cart {
    text-align: center;
    margin: 14px 0 0 0;
}

.sticky-top {
    top: 130px;
    z-index: 3;
}

.remove-product:focus {
    outline: none;
}

.remove-product img {
    width: 13px;
}

.remove-product {
    outline: none;
    border: 0;
    background-color: transparent;
    margin-left: 10px;
    opacity: .5;
    position: absolute;
    top: 0;
    right: 15px;

    width: 16px;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.remove-product:hover {
    opacity: 1;
}


.mb-cart-heading {
    display: none;
}

.checkout {
    padding: 0 0 100px 0;
}

.checkout .payment-method {
    margin: 0 0 15px 0;
}

.order-payment {
    background-color: #FBFAF9;
    padding: 25px;
}

.order-payment .payment-method {
    position: relative;
}

.order-payment .payment-method.payment-card label img {
    height: 19px;
}

.order-payment .payment-method label .payment-icon {
    position: absolute;
    top: 28px;
    right: 20px;
}

.order-payment .payment-method label .payment-icon img {
    max-height: 40px;
    display: block;
}

.order-delivery {
    background-color: #F8F9F9;
    padding: 20px;
    margin: 0 0 30px 0;
}

.order-delivery h3,
.order-payment h3 {
    margin: 0 0 10px 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.order-delivery .delivery-method {
    position: relative;
    margin: 0 0 20px 0;
}

.order-delivery .delivery-method input[type=radio],
.order-payment input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -6.5px;
}

.order-delivery .delivery-method input[type=radio]:checked ~ label,
.order-payment input[type=radio]:checked ~ label {
    border-color: #00aa4e;
    background-color: #f7fffb;
}

.order-delivery .delivery-method label,
.order-payment label {
    border: 1px solid #ccc;
    padding: 20px 20px 20px 45px;
    width: 100%;
    cursor: pointer;
    background: #fff;
    min-height: 95px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
}

.order-payment label,
.order-payment input[type="radio"]:not(:checked) + label,
.order-payment input[type="radio"]:checked + label {
    padding: 20px 74px 20px 44px
}

.order-delivery .delivery-method label:hover,
.order-payment label:hover {
    border-color: #009444;
}

.order-delivery .delivery-method label .delivery-name,
.order-payment label .payment-name {
    color: #40414E;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    margin: 0 0 5px 0;
}

.order-delivery .delivery-method label .delivery-desc,
.order-payment label .payment-desc {
    color: #7F8282;
    font-size: 12px;
    line-height: 16px;
}

.order-delivery h4 {
    margin: 0 0 15px 0;
    font-weight: bold;
}

.order-delivery .delivery-types {
    margin: 15px 0 0 0;
}

.order-delivery .delivery-type {
    position: relative;
    margin: 0 0 20px 0;
}

.order-delivery .delivery-type input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -6.5px;
}

.order-delivery .delivery-type input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #00aa4e;
    padding-right: 70px;
}

.order-delivery .delivery-type label {
    border: 1px solid #ccc;
    padding: 20px 10px 20px 45px;
    width: 100%;
    margin: 0;
    cursor: pointer;
    background: #fff;
}

.order-delivery .delivery-type label:hover {
    border-color: #00aa4e;
}

.order-delivery .delivery-type label:hover:before,
.order-payment label:hover:before {
    border: 6px solid #00aa4e !important;
}

.order-delivery .delivery-type label .delivery-type-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -15px;
}

.order-delivery .delivery-type label .delivery-type-icon img {
    max-height: 25px;
    display: block;
}

.order-delivery .delivery-type label .delivery-type-name {
    color: #40414E;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
}

.order-delivery .delivery-type label .delivery-type-desc {
    color: #7F8282;
    font-size: 12px;
    line-height: 16px;
}

.order-info {
    background-color: #F8F9F9;
    padding: 25px 20px;
    margin: 0 0 30px 0;
}

.date-info {
    background-color: #F8F9F9;
    padding: 25px;
    margin: 0 0 30px 0;
}

.date-info h3 {
    margin: 0 0 3px 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.order-info.payment-info {
    padding: 0;
}

.order-info h3 {
    margin: 0 0 10px 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.order-info label {
    margin: 0 0 4px 0;
    font-weight: 500;
    color: #333333;
}

.order-info .form-msg {
    color: #009444;
    font-size: 12px;
    margin: 5px 0 0 0;
}

.gift {
    border: 1px solid #CCCCCC;
    margin-bottom: 15px;
    padding: 20px 20px 18px 20px;
}

.gift h3 {
    margin: 0 0 3px 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.gift p {
    font-size: 14px;
    margin: 0;
}

.choose-date .btn {
    padding: 10px 15px;
    width: 100%;
    border-radius: 0;
    font-size: 18px;
    line-height: 22px;
}

.container .message-div {
    height: 50px;
    text-align: center;
    padding: 12px 0;
    background-color: #00aa4e;
    text-decoration: solid;
    color: white;
    font-size: 22px;
    line-height: 26px;
    margin: 15px 0 15px 0;
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .cart-list .product-name {
        font-size: 15px;
        line-height: 22px;
    }

    .total .sum ul li {
        font-size: 15px;
    }

    .total .sum ul li span {
        font-size: 16px;
    }

    .total .sum ul li:last-child {
        font-size: 17px;
    }

    .total .sum ul li:last-child span {
        font-size: 18px;
    }

    .cart-list .cart-row {
        padding: 25px 15px;
    }

    .cart-list .cart-product-price,
    .cart-list .total-price {
        font-size: 14px;
    }

    .cart-list .remove-product {
        font-size: 18px;
        margin-top: 1px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .total .product-row .product-name {
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 10px 0;
    }

    .total .product-row .product-old-price {
        text-align: right;
    }

    .promo-code {
        margin: 0 0 30px 0;
    }

    .total .product-row .total-sum {
        font-size: 14px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 991px) {
    .cart-list,
    .order-payment {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .cart-list .cart-list .product-old-price {
        text-align: center;
    }

    .cart-list .product-img {
        text-align: center;
    }

    .cart-list .cart-product-price,
    .cart-list .total-price {
        text-align: center;
        display: block;
        font-size: 14px;
        margin-top: 12px;
    }

    .cart-list .product-quantity button {
        width: 26px;
    }

    .cart-list .remove-product {
        font-size: 20px;
        margin: 0 0 0 0;
    }

    .cart-widget:after {
        bottom: -12px;
        z-index: 15;
        width: 315px;
    }

    .cart-container {
        top: 37px;
        width: 315px;
    }

    .cart-container:before {
        display: none
    }

    .cart {
        padding: 35px 0 40px;
    }

    .cart-headings {
        display: none;
    }

    .cart-list .product-desc {
        margin: 0 0 10px 0;
    }

    .cart-list {
        margin: 20px 0 15px 0;
    }

    .cart-list .product-name {
        font-size: 14px;
        line-height: 21px;
    }

    .cart-list .cart-row {
        padding: 25px 14px;
        border-bottom: 6px solid #fff;
    }

    .mb-cart-heading {
        margin: 15px 0 5px 0;
        display: block;
        color: #868695;

        font-size: 14px;
        text-align: center;
    }

    .promo-code .promo-code {
        padding: 20px 15px 26px 15px;
    }

    .total .product-row .product-name {
        font-size: 13px;
        line-height: 21px;
        margin: 0 0 6px 0;
        display: block;
    }

    .total.with-border {
        padding: 15px 0 10px 0;
    }

    .total .product-row .product-old-price {
        text-align: right;
    }

    .cart-widget:hover .cart-container,
    .cart-widget:hover:after {
        display: none;
    }

    .total {
        padding: 20px 14px 4px 14px;
    }

    .total .sum ul li {
        font-size: 14px;
    }

    .total .sum ul li span,
    .total .sum ul li:last-child {
        font-size: 15px;
    }

    .total .sum ul li:last-child span {
        font-size: 18px;
    }

    .cart-list .product-desc {
        font-size: 13px;
    }

    .total-action {
        padding: 0 14px 15px 14px;
    }

    .container .message-div {
        font-size: 20px;
        line-height: 26px;
        margin: 0 0 15px 0;
        padding: 12px 10px;
    }

    .gift {
        padding: 10px 15px 10px 15px;
    }

    .order-delivery {
        padding: 20px 15px;
        margin: 0 0 15px 0;
    }

    .total .product-row .product-price {
        font-size: 15px;
    }

    .order-delivery .delivery-type {
        margin: 0 0 15px 0;
    }

    .order-info {
        padding: 20px 15px;
        margin: 0 0 20px 0;
    }

    .select2-container {
        width: 100% !important;
        margin-bottom: 20px;
    }

    .total .product-row .total-sum,
    .total .product-row .quantity {
        font-size: 13px;
    }

    .cart-list .product-old-price {
        text-align: center;
        margin-top: 4px;
    }

    .total-action .btn {
        padding: 8px 10px 7px 10px;
    }

    .checkout .product-quantity button {
        height: 32px;
        width: 34px;
    }

    .checkout .product-quantity input[type=number] {
        height: 32px;
    }

    .remove-product img {
        width: 11px;
    }

    .total.with-border .sum {
        padding: 16px 15px 0 15px;
    }

    .order-delivery .delivery-method label .delivery-name,
    .order-payment label .payment-name,
    .order-delivery .delivery-type label .delivery-type-name {
        font-size: 14px
    }

    .order-delivery .delivery-method label, .order-payment label {
        padding: 10px 10px 10px 45px;
        display: block !important;
        min-height: auto;
    }

    .order-delivery .delivery-type label {
        padding: 16px 10px 16px 45px;
    }

    .total .product-row {
        padding: 15px;
    }

    .total.with-border h3 {
        padding: 0 15px;
    }

    .cart-empty img {
        height: 100px;
    }

    .order-delivery .delivery-method {
        margin: 0 0 15px 0;
    }

    .order-payment {
        padding: 20px 15px;
        margin-bottom: 15px;
    }

    .order-payment .payment-method label .payment-name {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 5px 0;
    }

    .promo-code {
        padding: 12px 15px 20px 15px;
        margin: 0 0 15px 0;
    }

    .total-action .checkbox label::after {
        margin-top: 4px;
    }

    .order-payment .payment-method label .payment-icon {
        top: 50%;
        right: 10px;
        margin-top: -15px;
    }

    .order-payment .payment-method label .payment-icon img {
        min-height: auto;
        max-width: 30px;
    }

    .order-payment label,
    .order-payment input[type="radio"]:not(:checked) + label,
    .order-payment input[type="radio"]:checked + label {
        padding: 15px 55px 15px 44px;
    }

    .promo-code .btn {
        padding: 8px 5px 7px 5px;
        width: 100%;
        min-width: inherit;
        font-size: 12px;
    }
    .promo-code .form-control {
        padding-left: 15px;
    }

    .total .sum ul li .hint,
    .total .sum ul li:last-child span.hint {
        font-size: 13px;
    }

    .gift h3 {
        margin: 0 0 0 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        text-transform: none;
    }

}

@media only screen and (min-width: 0px) and (max-width: 575px) {
    /*.promo-code .btn {*/
    /*    padding: 8px 15px 7px 15px;*/
    /*    width: 100%;*/
    /*}*/
}